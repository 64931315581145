"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const interfaces_1 = require("../../interfaces/interfaces");
const userStatusActions_1 = require("../actions/userStatusActions");
const initialState = {
    currentGroup: interfaces_1.RoleGroup.UNDEFINED,
    currentRole: interfaces_1.Role.UNDEFINED,
    currentRoleId: 0,
    permissions: [],
    roleAccess: {
        role_id: 0,
        countries: [],
        branches: [],
        access_to_branches: [],
    },
    authStatus: interfaces_1.AuthStatus.UNAUTHENTICATED,
    isUserIdle: false,
    isUnauthenticatedError: false,
    isMaintenance: false,
    mfa_setup: undefined,
};
const userStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case userStatusActions_1.SET_USER_STATUS:
            return Object.assign(Object.assign({}, state), action.payload);
        case userStatusActions_1.SET_CURRENT_GROUP:
            return Object.assign(Object.assign({}, state), { currentGroup: action.payload });
        case userStatusActions_1.SET_CURRENT_ROLE:
            return Object.assign(Object.assign({}, state), { currentRole: action.payload });
        case userStatusActions_1.SET_CURRENT_ROLE_ID:
            return Object.assign(Object.assign({}, state), { currentRoleId: action.payload });
        case userStatusActions_1.SET_ISUSERIDLE:
            return Object.assign(Object.assign({}, state), { isUserIdle: action.payload });
        case userStatusActions_1.SET_IS_UNAUTHENTICATED_ERROR:
            return Object.assign(Object.assign({}, state), { isUnauthenticatedError: action.payload });
        case userStatusActions_1.SET_IS_MAINTENANCE:
            return Object.assign(Object.assign({}, state), { isMaintenance: action.payload });
        case userStatusActions_1.RESET_USER_STATUS:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
exports.default = userStatusReducer;
