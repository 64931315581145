"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsMaintenanceAction = exports.SET_IS_MAINTENANCE = exports.setIsUnauthenticatedErrorAction = exports.SET_IS_UNAUTHENTICATED_ERROR = exports.resetUserStatusAction = exports.RESET_USER_STATUS = exports.setIsuseridleAction = exports.SET_ISUSERIDLE = exports.setCurrentRoleIdAction = exports.SET_CURRENT_ROLE_ID = exports.setCurrentRoleAction = exports.SET_CURRENT_ROLE = exports.setCurrentGroupAction = exports.SET_CURRENT_GROUP = exports.setUserStatusAction = exports.SET_USER_STATUS = void 0;
exports.SET_USER_STATUS = 'SET_USER_STATUS';
;
const setUserStatusAction = (userStatus) => ({
    type: exports.SET_USER_STATUS,
    payload: userStatus,
});
exports.setUserStatusAction = setUserStatusAction;
exports.SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
const setCurrentGroupAction = (group) => ({
    type: exports.SET_CURRENT_GROUP,
    payload: group,
});
exports.setCurrentGroupAction = setCurrentGroupAction;
exports.SET_CURRENT_ROLE = 'SET_CURRENT_ROLE';
const setCurrentRoleAction = (role) => ({
    type: exports.SET_CURRENT_ROLE,
    payload: role,
});
exports.setCurrentRoleAction = setCurrentRoleAction;
exports.SET_CURRENT_ROLE_ID = 'SET_CURRENT_ROLE_ID';
const setCurrentRoleIdAction = (roleId) => ({
    type: exports.SET_CURRENT_ROLE_ID,
    payload: roleId,
});
exports.setCurrentRoleIdAction = setCurrentRoleIdAction;
exports.SET_ISUSERIDLE = 'SET_ISUSERIDLE';
const setIsuseridleAction = (isUserIdle) => ({
    type: exports.SET_ISUSERIDLE,
    payload: isUserIdle,
});
exports.setIsuseridleAction = setIsuseridleAction;
exports.RESET_USER_STATUS = 'RESET_USER_STATUS';
const resetUserStatusAction = () => ({
    type: exports.RESET_USER_STATUS,
});
exports.resetUserStatusAction = resetUserStatusAction;
exports.SET_IS_UNAUTHENTICATED_ERROR = 'SET_IS_UNAUTHENTICATED_ERROR';
const setIsUnauthenticatedErrorAction = (isUnauthenticatedError) => ({
    type: exports.SET_IS_UNAUTHENTICATED_ERROR,
    payload: isUnauthenticatedError,
});
exports.setIsUnauthenticatedErrorAction = setIsUnauthenticatedErrorAction;
exports.SET_IS_MAINTENANCE = 'SET_IS_MAINTENANCE';
const setIsMaintenanceAction = (isMaintenance) => ({
    type: exports.SET_IS_MAINTENANCE,
    payload: isMaintenance,
});
exports.setIsMaintenanceAction = setIsMaintenanceAction;
