"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearHomeSearchAction = exports.CLEAR_HOME_SEARCH = exports.setUserApplicableErrorsAction = exports.SET_USER_APPLICABLE_ERRORS = exports.setIncludeNotHireableAssetsAction = exports.SET_INCLUDE_NOT_HIREABLE_ASSETS = exports.setWrittenOffAssetsAction = exports.SET_WRITTEN_OFF_ASSETS = exports.setAdvanceSearchAction = exports.SET_ADVANCE_SEARCH = exports.setBasicSearchAction = exports.SET_BASIC_SEARCH = void 0;
exports.SET_BASIC_SEARCH = 'SET_BASIC_SEARCH';
const setBasicSearchAction = (basicSearch) => ({
    type: exports.SET_BASIC_SEARCH,
    payload: basicSearch,
});
exports.setBasicSearchAction = setBasicSearchAction;
exports.SET_ADVANCE_SEARCH = 'SET_ADVANCE_SEARCH';
const setAdvanceSearchAction = (advanceSearch) => ({
    type: exports.SET_ADVANCE_SEARCH,
    payload: advanceSearch,
});
exports.setAdvanceSearchAction = setAdvanceSearchAction;
exports.SET_WRITTEN_OFF_ASSETS = 'SET_WRITTEN_OFF_ASSETS';
const setWrittenOffAssetsAction = (writtenOffAssets) => ({
    type: exports.SET_WRITTEN_OFF_ASSETS,
    payload: writtenOffAssets,
});
exports.setWrittenOffAssetsAction = setWrittenOffAssetsAction;
exports.SET_INCLUDE_NOT_HIREABLE_ASSETS = 'SET_INCLUDE_NOT_HIREABLE_ASSETS';
const setIncludeNotHireableAssetsAction = (includeNotHireableAssets) => ({
    type: exports.SET_INCLUDE_NOT_HIREABLE_ASSETS,
    payload: includeNotHireableAssets,
});
exports.setIncludeNotHireableAssetsAction = setIncludeNotHireableAssetsAction;
exports.SET_USER_APPLICABLE_ERRORS = 'SET_USER_APPLICABLE_ERRORS';
const setUserApplicableErrorsAction = (userApplicableErrors) => ({
    type: exports.SET_USER_APPLICABLE_ERRORS,
    payload: userApplicableErrors,
});
exports.setUserApplicableErrorsAction = setUserApplicableErrorsAction;
exports.CLEAR_HOME_SEARCH = 'CLEAR_HOME_SEARCH';
const clearHomeSearchAction = () => ({
    type: exports.CLEAR_HOME_SEARCH,
});
exports.clearHomeSearchAction = clearHomeSearchAction;
