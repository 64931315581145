"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.installReportExportAPI = exports.installListFiltersAPI = exports.installListAPI = exports.INSTALL_LIST_ACTION = void 0;
const _Api_1 = require("@Api");
const _Constants_1 = require("@Constants");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
exports.INSTALL_LIST_ACTION = 'INSTALL_LIST';
const installListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _Api_1.api)('install', {
    action: exports.INSTALL_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    search: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.installListAPI = installListAPI;
const installListFiltersAPI = () => (0, _Api_1.api)('install', {
    action: 'INSTALL_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.installListFiltersAPI = installListFiltersAPI;
const installReportExportAPI = (checkedInstallIds) => (0, _Api_1.api)('install', {
    action: 'INSTALL_EXTRACT',
    fillable: {
        install: {
            ids: checkedInstallIds,
        },
    },
}, undefined, undefined, true, true, undefined, undefined, true);
exports.installReportExportAPI = installReportExportAPI;
