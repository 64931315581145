"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterHasValue = exports.preserveDefaultFilters = exports.filtersToMultiSelectFn = exports.filtersBEtoFE = exports.filtersFEtoBE = void 0;
const interfaces_1 = require("@Interfaces/interfaces");
/**
 * Formats FE filters to expected BE format
 * @param filters frontend filters
 * @returns
 */
const filtersFEtoBE = (filters) => filters.reduce((filters, current) => {
    const { key, ids: id, text: name, value, search_by: searchBy, isByText } = current;
    if (searchBy === interfaces_1.SearchFilterType.ID && id.length > 0)
        return Object.assign(Object.assign({}, filters), { [key]: { id } });
    if (searchBy === interfaces_1.SearchFilterType.TEXT && name.length > 0)
        return Object.assign(Object.assign({}, filters), { [key]: { name } });
    if (searchBy === interfaces_1.SearchFilterType.ID_TEXT) {
        if (isByText && name.length > 0)
            return Object.assign(Object.assign({}, filters), { [key]: { name } });
        if (!isByText && id.length > 0)
            return Object.assign(Object.assign({}, filters), { [key]: { id } });
    }
    if (searchBy === interfaces_1.SearchFilterType.BOOLEAN && value !== null)
        return Object.assign(Object.assign({}, filters), { [key]: { value } });
    if (searchBy === interfaces_1.SearchFilterType.HASCONTENT && value !== null)
        return Object.assign(Object.assign({}, filters), { [key]: { value } });
    return filters;
}, {});
exports.filtersFEtoBE = filtersFEtoBE;
/**
 * Formats BE filters to FE format (adds needed control properties)
 * @param filters backend filters
 * @returns
 */
const filtersBEtoFE = (filters) => filters.map((f) => (Object.assign(Object.assign({}, f), { isByText: f.search_by === interfaces_1.SearchFilterType.TEXT ||
        (f.search_by === interfaces_1.SearchFilterType.ID_TEXT && f.default_search_by === interfaces_1.SearchFilterType.TEXT), ids: [], text: [], value: null })));
exports.filtersBEtoFE = filtersBEtoFE;
/**
 * Map function to format filter options into multiselect options
 * @param f filter option
 * @returns
 */
const filtersToMultiSelectFn = (f) => ({
    value: f.id,
    label: f.name,
    tooltip: f.description,
    originalOption: f,
});
exports.filtersToMultiSelectFn = filtersToMultiSelectFn;
/**
 * Reconciliates pristine filters with pre-selected default filters
 * @param filters filters from backend
 * @param defaults default selected filters
 * @returns
 */
const preserveDefaultFilters = (filters, defaults) => {
    const defaultKeys = defaults.map((d) => d.key);
    return filters.map((f) => {
        const index = defaultKeys.indexOf(f.key);
        if (index === -1)
            return f;
        const { isByText, ids, text, value } = defaults[index];
        return Object.assign(Object.assign({}, f), { isByText, ids, text, value });
    });
};
exports.preserveDefaultFilters = preserveDefaultFilters;
/**
 * Checks if search filter has value
 * @param f search filter
 * @returns true if it has value, false otherwise
 */
const filterHasValue = (f) => {
    switch (f.search_by) {
        case interfaces_1.SearchFilterType.ID:
            return f.ids.length > 0;
        case interfaces_1.SearchFilterType.ID_TEXT:
            return (f.isByText && f.text.length > 0) || (!f.isByText && f.ids.length > 0);
        case interfaces_1.SearchFilterType.TEXT:
            return f.text.length > 0;
        case interfaces_1.SearchFilterType.BOOLEAN:
            return f.value !== null;
        case interfaces_1.SearchFilterType.HASCONTENT:
            return f.value !== null;
        default:
            return false;
    }
};
exports.filterHasValue = filterHasValue;
