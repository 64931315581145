"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleSideBarAction = exports.TOGGLE_SIDE_BAR = exports.resetGlobalLoaderAction = exports.RESET_GLOBAL_LOADER = exports.setGlobalLoaderAction = exports.SET_GLOBAL_LOADER = void 0;
exports.SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
const setGlobalLoaderAction = (value) => ({
    type: exports.SET_GLOBAL_LOADER,
    payload: value,
});
exports.setGlobalLoaderAction = setGlobalLoaderAction;
exports.RESET_GLOBAL_LOADER = 'RESET_GLOBAL_LOADER';
const resetGlobalLoaderAction = () => ({
    type: exports.RESET_GLOBAL_LOADER,
});
exports.resetGlobalLoaderAction = resetGlobalLoaderAction;
exports.TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';
const toggleSideBarAction = () => ({
    type: exports.TOGGLE_SIDE_BAR,
});
exports.toggleSideBarAction = toggleSideBarAction;
