"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NAVIGATION_SECTIONS = exports.HOME_PAGE_SEARCH_TAB_OPTS = exports.MOBOTIX_POLYGON_TYPE_OPTS = exports.REGO_CHECK_POLYGON_OPTS = exports.VA_POLYGON_TYPE_OPTS = exports.SIDE_DRAWER_OPTS = exports.TO_PAGE_HEIGHT = exports.widthTransition = exports.PAGE_CONTAINER_MOBILE_VIEW_PADDING = exports.PAGE_CONTAINER_PADDING = exports.BREADCRUMB_MOBILE_VIEW_HEIGHT = exports.BREADCRUMB_HEIGHT = exports.TOPBAR_HEIGHT = exports.EXPANDED_SIDEBAR_WIDTH = exports.COLLAPSED_SIDEBAR_WIDTH = exports.DIAGNOSTICS_REFRESH_TIME = exports.HOME_PAGE = void 0;
const user_1 = require("@Api/user");
const site_1 = require("@Api/site");
const asset_1 = require("@Api/asset");
const package_1 = require("@Api/package");
const customer_1 = require("@Api/customer");
const install_1 = require("@Api/install");
const assetError_1 = require("@Api/assetError");
const outage_1 = require("@Api/outage");
const _Helpers_1 = require("@Helpers");
const deploymentStage_1 = require("@Api/deploymentStage");
const interfaces_1 = require("@Interfaces/interfaces");
const supportTasks_1 = require("@Api/supportTasks");
const assetTransfers_1 = require("@Api/assetTransfers");
const branchTasks_1 = require("@Api/branchTasks");
// Default home page when logged in
exports.HOME_PAGE = '/home';
// Diagnostics Manager
exports.DIAGNOSTICS_REFRESH_TIME = 10000;
// Side Bar
exports.COLLAPSED_SIDEBAR_WIDTH = 55;
exports.EXPANDED_SIDEBAR_WIDTH = 228;
// Top Bar
exports.TOPBAR_HEIGHT = 46;
// Breadcrumb
exports.BREADCRUMB_HEIGHT = 42;
exports.BREADCRUMB_MOBILE_VIEW_HEIGHT = 28;
// Page Container
exports.PAGE_CONTAINER_PADDING = 24;
exports.PAGE_CONTAINER_MOBILE_VIEW_PADDING = 8;
// Generic
const widthTransition = (theme) => theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
});
exports.widthTransition = widthTransition;
exports.TO_PAGE_HEIGHT = exports.TOPBAR_HEIGHT + exports.BREADCRUMB_HEIGHT;
exports.SIDE_DRAWER_OPTS = (0, _Helpers_1.addIds2levels)('subOpts', [
    { link: exports.HOME_PAGE, icon: 'HomeIcon', mainItemName: 'Home', subOpts: [] },
    {
        link: '',
        icon: 'EmojiTransportationIcon',
        mainItemName: 'Asset Management',
        subOpts: [
            {
                link: '/asset-management/assets',
                subOptName: 'Assets',
                restricted: [{ endpoint: 'asset', action: asset_1.ASSET_LIST_ACTION }],
            },
            {
                link: '/asset-management/asset-transfers',
                subOptName: 'Asset Transfers',
                restricted: [{ endpoint: 'asset_transfers', action: assetTransfers_1.ASSET_TRANSFERS_LIST_ACTION }],
            },
            {
                link: '/asset-management/customers',
                subOptName: 'Customers',
                restricted: [{ endpoint: 'customer', action: customer_1.CUSTOMER_LIST_ACTION }],
            },
            {
                link: '/asset-management/sites',
                subOptName: 'Sites',
                restricted: [{ endpoint: 'site', action: site_1.SITE_LIST_ACTION }],
            },
            {
                link: '/asset-management/branch-tasks',
                subOptName: 'Branch Tasks',
                restricted: [{ endpoint: 'branch_tasks', action: branchTasks_1.BRANCH_TASKS_LIST_ACTION }],
            },
            {
                link: '/asset-management/installer-tasks',
                subOptName: 'Installer Tasks',
                restricted: [{ endpoint: 'install', action: install_1.INSTALL_LIST_ACTION }],
            },
            {
                link: '/asset-management/support-tasks',
                subOptName: 'Support Tasks',
                restricted: [{ endpoint: 'support_tasks', action: supportTasks_1.SUPPORT_TASKS_LIST_ACTION }],
            },
        ],
        restricted: [
            { endpoint: 'asset', action: asset_1.ASSET_LIST_ACTION },
            { endpoint: 'asset_transfers', action: assetTransfers_1.ASSET_TRANSFERS_LIST_ACTION },
            { endpoint: 'customer', action: customer_1.CUSTOMER_LIST_ACTION },
            { endpoint: 'site', action: site_1.SITE_LIST_ACTION },
            { endpoint: 'branch_tasks', action: branchTasks_1.BRANCH_TASKS_LIST_ACTION },
            { endpoint: 'install', action: install_1.INSTALL_LIST_ACTION },
            { endpoint: 'support_tasks', action: supportTasks_1.SUPPORT_TASKS_LIST_ACTION },
        ],
    },
    {
        link: '',
        icon: 'AssessmentIcon',
        mainItemName: 'Reporting',
        subOpts: [
            {
                link: '/reporting/errors',
                subOptName: 'Errors',
                restricted: [{ endpoint: 'asset_error', action: assetError_1.ASSET_ERROR_LIST_ACTION }],
            },
            {
                link: '/reporting/outages',
                subOptName: 'Outages',
                restricted: [{ endpoint: 'outage', action: outage_1.OUTAGE_LIST_ACTION }],
            },
        ],
        restricted: [
            { endpoint: 'asset_error', action: assetError_1.ASSET_ERROR_LIST_ACTION },
            { endpoint: 'outage', action: outage_1.OUTAGE_LIST_ACTION },
        ],
    },
    {
        link: '',
        icon: 'CloudDownloadIcon',
        mainItemName: 'Deployment',
        subOpts: [
            {
                link: '/deployment/stages',
                subOptName: 'Stages',
                restricted: [
                    { endpoint: 'deployment_stage', action: deploymentStage_1.DEPLOYMENT_STAGE_INSERT_ACTION },
                    { endpoint: 'deployment_stage', action: deploymentStage_1.DEPLOYMENT_STAGE_UPDATE_ACTION },
                ],
            },
            {
                link: '/deployment/packages',
                subOptName: 'Packages',
                restricted: [{ endpoint: 'package', action: package_1.PACKAGE_LIST_ACTION }],
            },
        ],
        restricted: [
            { endpoint: 'deployment_stage', action: deploymentStage_1.DEPLOYMENT_STAGE_INSERT_ACTION },
            { endpoint: 'deployment_stage', action: deploymentStage_1.DEPLOYMENT_STAGE_UPDATE_ACTION },
            { endpoint: 'package', action: package_1.PACKAGE_LIST_ACTION },
        ],
    },
    {
        link: '',
        icon: 'Users',
        mainItemName: 'Users',
        subOpts: [{ link: '/users/users-list', subOptName: 'User List' }],
        restricted: [{ endpoint: 'user', action: user_1.USER_LIST_ACTION }],
    },
    {
        link: '',
        icon: 'HelpIcon',
        mainItemName: 'Help',
        subOpts: [
            {
                link: '/help/release-notes',
                subOptName: 'Release Notes',
                restricted: [{ endpoint: 'manual', action: 'MANUAL_GET_RELEASE_NOTES_URL' }],
            },
            { link: '/help/guides', subOptName: 'Guides' },
            { link: '/help/hires-flow', subOptName: 'On/Off Hires Flow' },
            {
                link: '/help/branch-manual',
                subOptName: 'Branch Manual',
                restricted: [{ endpoint: 'manual', action: 'MANUAL_GET_BRANCH_MANUAL_URL' }],
            },
            {
                link: '/help/support-manual',
                subOptName: 'Support Manual',
                restricted: [{ endpoint: 'manual', action: 'MANUAL_GET_SUPPORT_MANUAL_URL' }],
            },
            {
                link: '/help/user-management-manual',
                subOptName: 'User Management Manual',
                restricted: [{ endpoint: 'manual', action: 'MANUAL_GET_USER_MANAGEMENT_MANUAL_URL' }],
            },
        ],
    },
]);
exports.VA_POLYGON_TYPE_OPTS = [
    { id: 1, optName: 'Alert Zone', optValue: interfaces_1.PolygonType.ALERT_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
    { id: 2, optName: 'Record Zone', optValue: interfaces_1.PolygonType.RECORD_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
    { id: 3, optName: 'Exclusion Zone', optValue: interfaces_1.PolygonType.EXCLUSION_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
    { id: 4, optName: 'People Standing', optValue: interfaces_1.PolygonType.PEOPLE_STANDING_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
];
exports.REGO_CHECK_POLYGON_OPTS = [
    { id: 1, optName: 'Rego Zone', optValue: interfaces_1.PolygonType.REGO_ZONE, optBehav: interfaces_1.PolygonBehav.RECTANGLE },
    { id: 2, optName: 'Alert Zone', optValue: interfaces_1.PolygonType.ALERT_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
];
exports.MOBOTIX_POLYGON_TYPE_OPTS = [
    { id: 1, optName: 'Alert Zone', optValue: interfaces_1.PolygonType.ALERT_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
    { id: 2, optName: 'Record Zone', optValue: interfaces_1.PolygonType.RECORD_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
    { id: 3, optName: 'Exclusion Zone', optValue: interfaces_1.PolygonType.EXCLUSION_ZONE, optBehav: interfaces_1.PolygonBehav.POLYGON },
];
exports.HOME_PAGE_SEARCH_TAB_OPTS = [
    {
        id: 0,
        tabName: 'assets',
        tabLabel: 'Assets',
        placeHolder: 'Search for Assets...',
        restricted: [{ endpoint: 'asset', action: asset_1.ASSET_LIST_ACTION }],
    },
    {
        id: 1,
        tabName: 'sites',
        tabLabel: 'Sites',
        placeHolder: 'Search for Sites...',
        restricted: [{ endpoint: 'site', action: site_1.SITE_LIST_ACTION }],
    },
    {
        id: 2,
        tabName: 'customers',
        tabLabel: 'Customers',
        placeHolder: 'Search for Customers...',
        restricted: [{ endpoint: 'customer', action: customer_1.CUSTOMER_LIST_ACTION }],
    },
    {
        id: 3,
        tabName: 'errors',
        tabLabel: 'Errors',
        placeHolder: 'Search for Errors...',
        restricted: [{ endpoint: 'asset_error', action: assetError_1.ASSET_ERROR_LIST_ACTION }],
    },
    {
        id: 4,
        tabName: 'outages',
        tabLabel: 'Outages',
        placeHolder: 'Search for Outages...',
        restricted: [{ endpoint: 'outage', action: outage_1.OUTAGE_LIST_ACTION }],
    },
];
exports.NAVIGATION_SECTIONS = [
    {
        id: 1,
        title: 'Asset Management',
        navigationCards: [
            {
                id: 1.1,
                name: 'assets',
                count_key: 'assets',
                icon: 'VideocamIcon',
                link: '/asset-management/assets',
                quantity: '',
                isHighLighted: false,
                restricted: [{ endpoint: 'asset', action: asset_1.ASSET_LIST_ACTION }],
            },
            {
                id: 1.2,
                name: 'customers',
                count_key: 'customers',
                icon: 'PersonIcon',
                link: '/asset-management/customers',
                quantity: '',
                isHighLighted: false,
                restricted: [{ endpoint: 'customer', action: customer_1.CUSTOMER_LIST_ACTION }],
            },
            {
                id: 1.3,
                name: 'sites',
                count_key: 'sites',
                icon: 'LocationOnIcon',
                link: '/asset-management/sites',
                quantity: '',
                isHighLighted: false,
                restricted: [{ endpoint: 'site', action: site_1.SITE_LIST_ACTION }],
            },
            {
                id: 1.5,
                name: 'asset transfers',
                count_key: 'asset_transfers',
                icon: 'SwapHorizIcon',
                link: '/asset-management/asset-transfers',
                quantity: '',
                isHighLighted: false,
                restricted: [{ endpoint: 'asset_transfers', action: assetTransfers_1.ASSET_TRANSFERS_LIST_ACTION }],
            },
            {
                id: 1.6,
                name: 'branch tasks',
                count_key: 'branch_tasks',
                icon: 'DuoIcon',
                link: '/asset-management/branch-tasks',
                quantity: '',
                isHighLighted: true,
                restricted: [{ endpoint: 'branch_tasks', action: branchTasks_1.BRANCH_TASKS_LIST_ACTION }],
            },
            {
                id: 1.7,
                name: 'installer tasks',
                count_key: 'installer_tasks',
                icon: 'BuildIcon',
                link: '/asset-management/installer-tasks',
                quantity: '',
                isHighLighted: true,
                restricted: [{ endpoint: 'install', action: install_1.INSTALL_LIST_ACTION }],
            },
            {
                id: 1.8,
                name: 'support tasks',
                count_key: 'support_tasks',
                icon: 'HeadsetMicIcon',
                link: '/asset-management/support-tasks',
                quantity: '',
                isHighLighted: true,
                restricted: [{ endpoint: 'support_tasks', action: supportTasks_1.SUPPORT_TASKS_LIST_ACTION }],
            },
        ],
    },
    {
        id: 2,
        title: 'Reporting',
        navigationCards: [
            {
                id: 2.1,
                name: 'errors',
                count_key: 'asset_errors',
                icon: 'BugReportIcon',
                link: '/reporting/errors',
                quantity: '',
                isHighLighted: true,
                restricted: [{ endpoint: 'asset_error', action: assetError_1.ASSET_ERROR_LIST_ACTION }],
            },
            {
                id: 2.2,
                name: 'outages',
                count_key: 'outages',
                icon: 'CloudOffIcon',
                link: '/reporting/outages',
                quantity: '',
                isHighLighted: true,
                restricted: [{ endpoint: 'outage', action: outage_1.OUTAGE_LIST_ACTION }],
            },
        ],
    },
];
