"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZoneErrors = exports.getLenses4Submission = exports.polygonArrByLens = exports.convertPointsToMobotixFormatStr = exports.formatPolygonPointsWithMobotixOffset = exports.flattenedPoints = exports.getPolygonFillColor = exports.getPolygonTypeColor = exports.polygonKeyByType = exports.polygonTypeByKey = exports.getNextPolygonId = exports.getDevicePolygons = void 0;
const interfaces_1 = require("@Interfaces/interfaces");
const Theme_1 = require("@Styles/Theme");
/**
 * Formats zones from device into array of polygons
 * @param device device
 * @param tabKey tab key
 * @returns polygon array
 */
const getDevicePolygons = (device, tabKey, options) => {
    var _a;
    const arr = [];
    if ((_a = device === null || device === void 0 ? void 0 : device.device_type_info) === null || _a === void 0 ? void 0 : _a.configuration) {
        Object.entries(device.device_type_info.configuration.lenses)
            .forEach(([lensName, config]) => {
            Object.entries(config[tabKey] || {}).forEach(([polygonKey, lensZone]) => {
                lensZone.forEach((lz) => {
                    const polygonType = (0, exports.polygonTypeByKey)(polygonKey);
                    const opt = options.find((o) => o.optValue === polygonType);
                    if (opt) {
                        const pol = {
                            polygonId: (0, exports.getNextPolygonId)(arr, lensName, polygonKey),
                            polygonKey,
                            polygonType,
                            polygonBehav: opt.optBehav,
                            lensName,
                            points: lz.points,
                        };
                        arr.push(pol);
                    }
                });
            });
        });
    }
    return arr;
};
exports.getDevicePolygons = getDevicePolygons;
/**
 * Returns next polygon id based on current polygons for the same lens and type
 * @param polygonArr polygon array list
 * @param lensName lens name
 * @param polygonKey polygon key
 * @returns next polygon id number
 */
const getNextPolygonId = (polygonArr, lensName, polygonKey) => {
    const sortedIds = polygonArr.filter((pol) => (pol.lensName === lensName && pol.polygonKey === polygonKey))
        .map((pol) => pol.polygonId)
        .sort((a, b) => a - b);
    if (!sortedIds.length)
        return 1;
    for (let index = 0; index < sortedIds.length; index += 1) {
        const id = sortedIds[index];
        if (id !== (index + 1))
            return (index + 1);
    }
    return sortedIds[sortedIds.length - 1] + 1;
};
exports.getNextPolygonId = getNextPolygonId;
/**
 * Given a polygon key, returns its correspondent polygon type
 * @param key polygon key
 * @returns polygon type
 */
const polygonTypeByKey = (key) => {
    if (key === interfaces_1.PolygonKey.alert_zones)
        return interfaces_1.PolygonType.ALERT_ZONE;
    if (key === interfaces_1.PolygonKey.exclusion_zones)
        return interfaces_1.PolygonType.EXCLUSION_ZONE;
    if (key === interfaces_1.PolygonKey.record_zones)
        return interfaces_1.PolygonType.RECORD_ZONE;
    if (key === interfaces_1.PolygonKey.people_standing)
        return interfaces_1.PolygonType.PEOPLE_STANDING_ZONE;
    return interfaces_1.PolygonType.REGO_ZONE;
};
exports.polygonTypeByKey = polygonTypeByKey;
/**
 * Given a polygon type, returns its correspondent polygon key
 * @param type polygon type
 * @returns polygon key
 */
const polygonKeyByType = (type) => {
    if (type === interfaces_1.PolygonType.ALERT_ZONE)
        return interfaces_1.PolygonKey.alert_zones;
    if (type === interfaces_1.PolygonType.EXCLUSION_ZONE)
        return interfaces_1.PolygonKey.exclusion_zones;
    if (type === interfaces_1.PolygonType.RECORD_ZONE)
        return interfaces_1.PolygonKey.record_zones;
    if (type === interfaces_1.PolygonType.PEOPLE_STANDING_ZONE)
        return interfaces_1.PolygonKey.people_standing;
    return interfaces_1.PolygonKey.rego_zones;
};
exports.polygonKeyByType = polygonKeyByType;
/**
 * Return polygon color
 * @param polygonType polygon type
 * @returns css color
 */
const getPolygonTypeColor = (polygonType) => {
    switch (polygonType) {
        case interfaces_1.PolygonType.ALERT_ZONE:
            return Theme_1.visionPallete.polygon.alert;
        case interfaces_1.PolygonType.RECORD_ZONE:
            return Theme_1.visionPallete.polygon.record;
        case interfaces_1.PolygonType.EXCLUSION_ZONE:
            return Theme_1.visionPallete.polygon.exclusion;
        case interfaces_1.PolygonType.PEOPLE_STANDING_ZONE:
            return Theme_1.visionPallete.polygon.people;
        case interfaces_1.PolygonType.REGO_ZONE:
            return Theme_1.visionPallete.polygon.people;
        default:
            return Theme_1.visionPallete.polygon.people;
    }
};
exports.getPolygonTypeColor = getPolygonTypeColor;
/**
 * Return polygon fill color
 * @param polygonType polygon type
 * @returns css color
 */
const getPolygonFillColor = (polygonType) => {
    if (polygonType === interfaces_1.PolygonType.ALERT_ZONE)
        return Theme_1.visionPallete.polygonFill.alert;
    if (polygonType === interfaces_1.PolygonType.RECORD_ZONE)
        return Theme_1.visionPallete.polygonFill.record;
    if (polygonType === interfaces_1.PolygonType.EXCLUSION_ZONE)
        return Theme_1.visionPallete.polygonFill.exclusion;
    if (polygonType === interfaces_1.PolygonType.PEOPLE_STANDING_ZONE)
        return Theme_1.visionPallete.polygonFill.people;
    if (polygonType === interfaces_1.PolygonType.REGO_ZONE)
        return Theme_1.visionPallete.polygonFill.people;
    return Theme_1.visionPallete.polygonFill.people;
};
exports.getPolygonFillColor = getPolygonFillColor;
/**
 * Flatenns an array of points, concatenning the current mouse position unless finished
 * @param points array of points [x,y][]
 * @param isFinished if true, we don't concatenate the current mouse position
 * @param curMousePos current mouse position [x,y]
 * @returns flat array of points [x, y, ... ,xn, yn]
 */
const flattenedPoints = (points, isFinished = false, curMousePos) => {
    if (!points)
        return [];
    const result = points.concat(isFinished ? [] : curMousePos).reduce((a, b) => a && a.concat(b), []);
    return result;
};
exports.flattenedPoints = flattenedPoints;
/**
 * Returns return polygon point with Mobotix offset
 * @param point
 * @returns point
 */
const formatPolygonPointsWithMobotixOffset = (point) => [
    Math.round(1080 * point[0] + 94.0),
    Math.round(910 * (1 - point[1]) + 17.0),
];
exports.formatPolygonPointsWithMobotixOffset = formatPolygonPointsWithMobotixOffset;
/**
 * Return zone points string with Mobotix offset
 * @param plg polygon
 * @returns zone points string with Mobotix offset
 */
const convertPointsToMobotixFormatStr = (plg) => {
    let res = plg.polygonKey === interfaces_1.PolygonKey.exclusion_zones ? '!' : '';
    res += plg.lensName === 'left' ? '1,poly=' : '0,poly=';
    const offsetPoints = plg.points.map((p) => (0, exports.formatPolygonPointsWithMobotixOffset)(p));
    res += offsetPoints.map((p) => `${p[0]}x${p[1]}`).join('/');
    return res;
};
exports.convertPointsToMobotixFormatStr = convertPointsToMobotixFormatStr;
/**
 * Returns a lens object with its correspondent polygon arrays
 * @param polygonArr polygon array
 * @return eg { left: [...], right: [...]}
 */
const polygonArrByLens = (polygonArr, device) => {
    var _a, _b;
    return polygonArr.reduce((acc, pol) => acc[pol.lensName]
        ? Object.assign(Object.assign({}, acc), { [pol.lensName]: [...acc[pol.lensName], pol] }) : Object.assign(Object.assign({}, acc), { [pol.lensName]: [pol] }), Object.keys(((_b = (_a = device.device_type_info) === null || _a === void 0 ? void 0 : _a.configuration) === null || _b === void 0 ? void 0 : _b.lenses) || {})
        .reduce((acc, lens) => (Object.assign(Object.assign({}, acc), { [lens]: [] })), {}));
};
exports.polygonArrByLens = polygonArrByLens;
/**
 * Formats FE zones to BE lens configuration
 * @param device device
 * @param tabs camera config tabs
 * @returns
 */
const getLenses4Submission = (device, tabs) => {
    var _a, _b, _c, _d;
    const leftConf = ((_b = (_a = device.device_type_info) === null || _a === void 0 ? void 0 : _a.configuration) === null || _b === void 0 ? void 0 : _b.lenses.left) || { name: 'left', type: '' };
    const rightConf = (_d = (_c = device.device_type_info) === null || _c === void 0 ? void 0 : _c.configuration) === null || _d === void 0 ? void 0 : _d.lenses.right;
    const VATab = tabs.find((t) => t.key === interfaces_1.TabKey.video_analytics && t.isVisible);
    const MobTab = tabs.find((t) => t.key === interfaces_1.TabKey.mobotix && t.isVisible);
    const RegTab = tabs.find((t) => t.key === interfaces_1.TabKey.regcheck && t.isVisible);
    const filterFn = (lens, key) => (p) => p.lensName === lens && p.polygonKey === key;
    const plg2LensZone = (p) => ({
        id: p.polygonId,
        name: `${p.lensName} ${p.polygonType} ${p.polygonId}`,
        points: p.points,
    });
    const res = Object.assign({ left: Object.assign(Object.assign(Object.assign({ name: leftConf.name, type: leftConf.type }, (VATab ? {
            video_analytics: {
                alert_zones: VATab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.alert_zones)).map(plg2LensZone),
                record_zones: VATab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.record_zones)).map(plg2LensZone),
                exclusion_zones: VATab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.exclusion_zones)).map(plg2LensZone),
                people_standing: VATab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.people_standing)).map(plg2LensZone),
            },
        } : {})), (MobTab ? {
            mobotix: {
                alert_zones: MobTab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.alert_zones)).map(plg2LensZone),
                record_zones: MobTab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.record_zones)).map(plg2LensZone),
                exclusion_zones: MobTab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.exclusion_zones)).map(plg2LensZone),
            },
        } : {})), (RegTab ? {
            regcheck: {
                rego_zones: RegTab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.rego_zones)).map(plg2LensZone),
                alert_zones: RegTab.polygonArr.filter(filterFn('left', interfaces_1.PolygonKey.alert_zones)).map(plg2LensZone),
            },
        } : {})) }, (rightConf ? {
        right: Object.assign(Object.assign(Object.assign({ name: rightConf.name, type: rightConf.type }, (VATab ? {
            video_analytics: {
                alert_zones: VATab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.alert_zones)).map(plg2LensZone),
                record_zones: VATab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.record_zones)).map(plg2LensZone),
                exclusion_zones: VATab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.exclusion_zones)).map(plg2LensZone),
                people_standing: VATab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.people_standing)).map(plg2LensZone),
            },
        } : {})), (MobTab ? {
            mobotix: {
                alert_zones: MobTab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.alert_zones)).map(plg2LensZone),
                record_zones: MobTab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.record_zones)).map(plg2LensZone),
                exclusion_zones: MobTab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.exclusion_zones)).map(plg2LensZone),
            },
        } : {})), (RegTab ? {
            regcheck: {
                rego_zones: RegTab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.rego_zones)).map(plg2LensZone),
                alert_zones: RegTab.polygonArr.filter(filterFn('right', interfaces_1.PolygonKey.alert_zones)).map(plg2LensZone),
            },
        } : {}))
    } : {}));
    return res;
};
exports.getLenses4Submission = getLenses4Submission;
/**
 * Given the polygons of a zone, returns array of error messages or empty array if valid
 * @param polygonArr polygon array
 * @param device device
 * @param key tab key
 * @returns array of error messages
 */
const getZoneErrors = (polygonArr, device, key) => {
    const lensPol = (0, exports.polygonArrByLens)(polygonArr, device);
    const err = [];
    // Validate Video Analytics
    if (key === interfaces_1.TabKey.video_analytics) {
        // Must have more than 1 people standing zone per lens
        const lensesLessThan2People = Object.entries(lensPol).reduce((acc, [lens, polArr]) => polArr.filter((p) => p.polygonKey === interfaces_1.PolygonKey.people_standing).length < 2
            ? [...acc, lens] : acc, []);
        if (lensesLessThan2People.length) {
            err.push(`VA: Please add 2 or more people standing zones to ${lensesLessThan2People.join(' and ')} lens`);
        }
        // Must have either one alert zone or one record zone per lens
        const lensesNoAlertNorRecord = Object.entries(lensPol).reduce((acc, [lens, polArr]) => polArr.filter((p) => [interfaces_1.PolygonKey.alert_zones, interfaces_1.PolygonKey.record_zones].includes(p.polygonKey)).length === 0
            ? [...acc, lens] : acc, []);
        if (lensesNoAlertNorRecord.length) {
            err.push(`VA: Please add either one alert or one record zone to ${lensesNoAlertNorRecord.join(' and ')} lens`);
        }
        // No empty zones allowed -> (Commented because the other 2 errors will show up if a lens is empty)
        // const lensesEmpty = Object.entries(lensPol)
        //   .reduce((acc, [lens, polArr]) => !polArr.length ? [...acc, lens] : acc, [] as string[]);
        // if (lensesEmpty.length) {
        //   err.push(`VA: Please add zones to ${lensesEmpty.join(' and ')} lens`);
        // }
        return err;
    }
    // Validate RegoCheck
    if (key === interfaces_1.TabKey.regcheck) {
        // At least one zone in one lens
        const someHasRegoZones = Object.entries(lensPol)
            .some(([_, polArr]) => polArr.filter((p) => p.polygonKey === interfaces_1.PolygonKey.rego_zones).length);
        if (!someHasRegoZones) {
            err.push('RegCheck: Please add at least one rego check zone');
        }
        // One alert zone at most
        const alertZones = polygonArr.filter((p) => p.polygonKey === interfaces_1.PolygonKey.alert_zones);
        if (alertZones.length > 1) {
            err.push('RegCheck: Only one alert zone is allowed in total.');
        }
        return err;
    }
    return err;
};
exports.getZoneErrors = getZoneErrors;
