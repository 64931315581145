"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.branchLookupAPI = exports.branchAppUserExportAPI = exports.BRANCH_TWOBULLS_USERS = exports.branchInfoListAPI = exports.branchInfoAPI = void 0;
const _1 = require(".");
const branchInfoAPI = (branch_id) => (0, _1.api)('branch', {
    action: 'BRANCH_INFO',
    fillable: {
        branch: {
            id: branch_id,
        },
    },
});
exports.branchInfoAPI = branchInfoAPI;
const branchInfoListAPI = (branch_ids) => (0, _1.api)('branch', {
    action: 'BRANCH_INFO_LIST',
    fillable: {
        branch: {
            id: branch_ids,
        },
    },
});
exports.branchInfoListAPI = branchInfoListAPI;
exports.BRANCH_TWOBULLS_USERS = 'BRANCH_TWOBULLS_USERS';
const branchAppUserExportAPI = (branchIds) => (0, _1.api)('branch', {
    action: exports.BRANCH_TWOBULLS_USERS,
    fillable: {
        branch: {
            id: branchIds,
        },
    },
});
exports.branchAppUserExportAPI = branchAppUserExportAPI;
const branchLookupAPI = (search_term) => (0, _1.api)('branch', {
    action: 'BRANCH_LOOKUP',
    fillable: {
        search_term,
    },
});
exports.branchLookupAPI = branchLookupAPI;
