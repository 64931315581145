"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactUpdateApi = exports.CONTACT_UPDATE_ACTION = exports.contactInsertApi = exports.CONTACT_INSERT_ACTION = exports.contactLookupApi = void 0;
const _Api_1 = require("@Api");
const contactLookupApi = (term, customer_id) => (0, _Api_1.api)('contact', {
    action: 'CONTACT_LOOKUP',
    fillable: { search_term: term, customer_id },
});
exports.contactLookupApi = contactLookupApi;
exports.CONTACT_INSERT_ACTION = 'CONTACT_INSERT';
const contactInsertApi = (contact) => (0, _Api_1.api)('contact', {
    action: exports.CONTACT_INSERT_ACTION,
    fillable: { contact },
});
exports.contactInsertApi = contactInsertApi;
exports.CONTACT_UPDATE_ACTION = 'CONTACT_UPDATE';
const contactUpdateApi = (contact) => (0, _Api_1.api)('contact', {
    action: exports.CONTACT_UPDATE_ACTION,
    fillable: { contact },
});
exports.contactUpdateApi = contactUpdateApi;
