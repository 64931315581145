"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.homeNavCountsAPI = exports.homeSearchFiltersAPI = void 0;
const _Api_1 = require("@Api");
const homeSearchFiltersAPI = () => (0, _Api_1.api)('home', {
    action: 'HOME_SEARCH_FILTERS',
});
exports.homeSearchFiltersAPI = homeSearchFiltersAPI;
const homeNavCountsAPI = () => (0, _Api_1.api)('home', {
    action: 'HOME_COUNTS',
});
exports.homeNavCountsAPI = homeNavCountsAPI;
