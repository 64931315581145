"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanupDisgnosticsAction = exports.CLEANUP_DIAGNOSTICS = exports.checkDiagnosticsAction = exports.CHECK_DIAGNOSTICS = exports.addRunningDisgnosticsAction = exports.ADD_RUNNING_DIAGNOSTICS = exports.removeToRunDiagnosticAction = exports.REMOVE_TO_RUN_DIAGNOSTIC = exports.addToRunDiagnosticAction = exports.ADD_TO_RUN_DIAGNOSTIC = void 0;
exports.ADD_TO_RUN_DIAGNOSTIC = 'ADD_TO_RUN_DIAGNOSTIC';
const addToRunDiagnosticAction = (assetId) => ({
    type: exports.ADD_TO_RUN_DIAGNOSTIC,
    payload: assetId,
});
exports.addToRunDiagnosticAction = addToRunDiagnosticAction;
exports.REMOVE_TO_RUN_DIAGNOSTIC = 'REMOVE_TO_RUN_DIAGNOSTIC';
const removeToRunDiagnosticAction = (assetId) => ({
    type: exports.REMOVE_TO_RUN_DIAGNOSTIC,
    payload: assetId,
});
exports.removeToRunDiagnosticAction = removeToRunDiagnosticAction;
exports.ADD_RUNNING_DIAGNOSTICS = 'ADD_RUNNING_DIAGNOSTICS';
const addRunningDisgnosticsAction = (assetIds) => ({
    type: exports.ADD_RUNNING_DIAGNOSTICS,
    payload: assetIds,
});
exports.addRunningDisgnosticsAction = addRunningDisgnosticsAction;
exports.CHECK_DIAGNOSTICS = 'CHECK_DIAGNOSTICS';
const checkDiagnosticsAction = (response) => ({
    type: exports.CHECK_DIAGNOSTICS,
    payload: response,
});
exports.checkDiagnosticsAction = checkDiagnosticsAction;
exports.CLEANUP_DIAGNOSTICS = 'CLEANUP_DIAGNOSTICS';
const cleanupDisgnosticsAction = () => ({
    type: exports.CLEANUP_DIAGNOSTICS,
});
exports.cleanupDisgnosticsAction = cleanupDisgnosticsAction;
