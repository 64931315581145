"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const sortListByHeader = (hdr, sortedByHeader, sortDirection, setSortDirection, setSortedByHeader, search) => {
    if (hdr.sortable) {
        // If header is sortable
        if (hdr.id === (sortedByHeader === null || sortedByHeader === void 0 ? void 0 : sortedByHeader.id)) {
            if (sortDirection === ListHeaderConst_1.DEFAULT_SORT_DIRECTION) {
                setSortDirection(ListHeaderConst_1.OPPOSITE_SORT_DIRECTION);
            }
            else {
                setSortedByHeader(undefined);
                setSortDirection(ListHeaderConst_1.DEFAULT_SORT_DIRECTION);
            }
        }
        else {
            // If not => select sort header and set default sorting direction
            setSortedByHeader(hdr);
            setSortDirection(ListHeaderConst_1.DEFAULT_SORT_DIRECTION);
        }
        search();
    }
};
exports.default = sortListByHeader;
