"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deviceEventImageUrlsAPI = exports.deviceEventListAPI = exports.deviceConfigUpdateAPI = void 0;
const _1 = require(".");
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Device#action-update
const deviceConfigUpdateAPI = (device, lenses) => {
    var _a;
    return (0, _1.api)('device', {
        action: 'DEVICE_CONFIG_UPDATE',
        fillable: {
            device: {
                id: device.id,
                configuration: Object.assign(Object.assign({}, (((_a = device.device_type_info) === null || _a === void 0 ? void 0 : _a.configuration) || {})), { lenses }),
            },
        },
    });
};
exports.deviceConfigUpdateAPI = deviceConfigUpdateAPI;
const deviceEventListAPI = (id, product) => (0, _1.api)('device', {
    action: 'DEVICE_EVENT_LIST',
    fillable: {
        device: {
            id,
            product,
        },
    },
});
exports.deviceEventListAPI = deviceEventListAPI;
const deviceEventImageUrlsAPI = (id, event_sequence, product) => (0, _1.api)('device', {
    action: 'DEVICE_EVENT_IMAGE_URLS',
    fillable: {
        device: {
            id,
            event_sequence,
            product,
        },
    },
});
exports.deviceEventImageUrlsAPI = deviceEventImageUrlsAPI;
