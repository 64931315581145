"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFootageLinkTypeForDisplay = exports.FOOTAGE_LINK_TYPE_CHOICES = exports.getFootageFormatForDisplay = exports.FOOTAGE_FORMAT_CHOICES = void 0;
// Returns a function that converts a machine-readable choice value
// into a human readable value for display
const buildChoiceLookup = (choices) => {
    const lookup = new Map();
    choices.forEach(choice => {
        lookup.set(choice.value, choice.label);
    });
    return (value) => {
        const result = lookup.get(value);
        if (result !== undefined) {
            return result;
        }
        return value;
    };
};
exports.FOOTAGE_FORMAT_CHOICES = [
    {
        label: 'Full footage',
        value: 'full_footage',
    },
    {
        label: 'Time-lapse',
        value: 'time_lapse',
    },
];
exports.getFootageFormatForDisplay = buildChoiceLookup(exports.FOOTAGE_FORMAT_CHOICES);
exports.FOOTAGE_LINK_TYPE_CHOICES = [
    {
        label: 'Dropbox',
        value: 'dropbox',
    },
    {
        label: 'Amazon',
        value: 'amazon',
    },
];
exports.getFootageLinkTypeForDisplay = buildChoiceLookup(exports.FOOTAGE_LINK_TYPE_CHOICES);
