"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerUpdateAPI = exports.CUSTOMER_UPDATE_ACTION = exports.customerListFiltersAPI = exports.customerListAPI = exports.CUSTOMER_LIST_ACTION = exports.customerLookupApi = exports.customerInfoAPI = exports.CUSTOMER_INFO_ACTION = void 0;
const _Api_1 = require("@Api");
const _Constants_1 = require("@Constants");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
exports.CUSTOMER_INFO_ACTION = 'CUSTOMER_INFO';
const customerInfoAPI = (customer_id) => (0, _Api_1.api)('customer', {
    action: exports.CUSTOMER_INFO_ACTION,
    fillable: {
        customer: {
            id: customer_id,
        },
    },
});
exports.customerInfoAPI = customerInfoAPI;
const customerLookupApi = (term) => (0, _Api_1.api)('customer', {
    action: 'CUSTOMER_LOOKUP',
    fillable: { search_term: term },
});
exports.customerLookupApi = customerLookupApi;
exports.CUSTOMER_LIST_ACTION = 'CUSTOMER_LIST';
const customerListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _Api_1.api)('customer', {
    action: exports.CUSTOMER_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    search: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.customerListAPI = customerListAPI;
const customerListFiltersAPI = () => (0, _Api_1.api)('customer', {
    action: 'CUSTOMER_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.customerListFiltersAPI = customerListFiltersAPI;
exports.CUSTOMER_UPDATE_ACTION = 'CUSTOMER_UPDATE';
const customerUpdateAPI = (id, customer) => (0, _Api_1.api)('customer', {
    action: exports.CUSTOMER_UPDATE_ACTION,
    fillable: {
        customer: Object.assign({ id }, customer),
    },
});
exports.customerUpdateAPI = customerUpdateAPI;
