"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetOutageExportAPI = exports.OUTAGE_LIST_OUTAGE_ACTION = exports.outageListFiltersAPI = exports.outageListAPI = exports.OUTAGE_LIST_ACTION = void 0;
const _Api_1 = require("@Api");
const _Constants_1 = require("@Constants");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
exports.OUTAGE_LIST_ACTION = 'OUTAGE_LIST';
const outageListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _Api_1.api)('outage', {
    action: exports.OUTAGE_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.outageListAPI = outageListAPI;
const outageListFiltersAPI = () => (0, _Api_1.api)('outage', {
    action: 'OUTAGE_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.outageListFiltersAPI = outageListFiltersAPI;
exports.OUTAGE_LIST_OUTAGE_ACTION = 'OUTAGE_LIST_EXPORT';
const assetOutageExportAPI = (branchIds) => (0, _Api_1.api)('outage', {
    action: exports.OUTAGE_LIST_OUTAGE_ACTION,
    fillable: {
        branch: {
            id: branchIds,
        },
    },
});
exports.assetOutageExportAPI = assetOutageExportAPI;
