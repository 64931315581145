"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const alertActions_1 = require("@Store/actions/alertActions");
const defaultState = [];
const alertReducer = (state = defaultState, action) => {
    switch (action.type) {
        case alertActions_1.ERROR_ALERT:
            return [...state, { id: (0, lodash_1.random)(1, 99999), message: action.payload, type: 'error' }];
        case alertActions_1.SUCCESS_ALERT:
            return [...state, { id: (0, lodash_1.random)(1, 99999), message: action.payload, type: 'success' }];
        case alertActions_1.INFO_ALERT:
            return [...state, { id: (0, lodash_1.random)(1, 99999), message: action.payload, type: 'info' }];
        case alertActions_1.WARNING_ALERT:
            return [...state, { id: (0, lodash_1.random)(1, 99999), message: action.payload, type: 'warning' }];
        case alertActions_1.REMOVE_ALERT:
            return [...state].filter((alrt) => alrt.id !== action.payload);
        case alertActions_1.CLEAR_ALERT_MESSAGES:
            return [];
        default:
            return state;
    }
};
exports.default = alertReducer;
