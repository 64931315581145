"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGeoloationFromStr = exports.getAssetGeolocation = exports.formatGeolocation = exports.getGeoErrors = exports.geoValidations = void 0;
const _Helpers_1 = require("@Helpers");
exports.geoValidations = {
    latitudeHasOneLetter: (v) => {
        var _a;
        const match = (_a = v.split(',')[0]) === null || _a === void 0 ? void 0 : _a.match(/([A-Za-z])/g);
        if (match && match.length > 1)
            return false;
        return true;
    },
    longitudeHasOneLetter: (v) => {
        var _a;
        const match = (_a = v.split(',')[1]) === null || _a === void 0 ? void 0 : _a.match(/([A-Za-z])/g);
        if (match && match.length > 1)
            return false;
        return true;
    },
    minorNumberLat: (v) => !v || (0, exports.formatGeolocation)(v).slice(0, 1) === '-',
    latValueRange: (v) => !v || Math.abs(parseFloat(v.split(',')[0])) < 90,
    lngValueRange: (v) => !v || v.split(',').length < 2 || Math.abs(parseFloat(v.split(',')[1])) < 180,
};
const getGeoErrors = (errors) => {
    var _a, _b, _c, _d, _e, _f;
    return [
        { isErr: ((_a = errors === null || errors === void 0 ? void 0 : errors.geolocation) === null || _a === void 0 ? void 0 : _a.type) === 'required', err: 'Geolocation is required.' },
        {
            isErr: ((_b = errors === null || errors === void 0 ? void 0 : errors.geolocation) === null || _b === void 0 ? void 0 : _b.type) === 'minorNumberLat',
            err: 'Australia and New Zealand latitude should start with minus.',
        },
        {
            isErr: ((_c = errors === null || errors === void 0 ? void 0 : errors.geolocation) === null || _c === void 0 ? void 0 : _c.type) === 'latitudeHasOneLetter' ||
                ((_d = errors === null || errors === void 0 ? void 0 : errors.geolocation) === null || _d === void 0 ? void 0 : _d.type) === 'longitudeHasOneLetter',
            err: 'Format is invalid.',
        },
        {
            isErr: ((_e = errors === null || errors === void 0 ? void 0 : errors.geolocation) === null || _e === void 0 ? void 0 : _e.type) === 'latValueRange',
            err: 'Latitude should be between -90 and 90.',
        },
        {
            isErr: ((_f = errors === null || errors === void 0 ? void 0 : errors.geolocation) === null || _f === void 0 ? void 0 : _f.type) === 'lngValueRange',
            err: 'Longitude should be between -180 and 180.',
        },
    ];
};
exports.getGeoErrors = getGeoErrors;
/**
 * Formats geolocation string to strip out letters and degree simbols. It also add minus to location if not found
 * @param geoData geolocation string
 * @returns latitude, longitude
 */
const formatGeolocation = (geoData) => {
    if ((geoData === null || geoData === void 0 ? void 0 : geoData.includes('S')) || (geoData === null || geoData === void 0 ? void 0 : geoData.includes('W'))) {
        const geoDataStrWithHyphen = `-${geoData}`;
        const geoDataStrWithoutSpaceAndLetter = geoDataStrWithHyphen.replace(/°\s[A-Z]/g, '');
        return geoDataStrWithoutSpaceAndLetter;
    }
    return geoData;
};
exports.formatGeolocation = formatGeolocation;
/**
 * Retrieves location object from asset detail if found
 * @param asset asset detail
 * @returns returns location object or null
 */
const getAssetGeolocation = (asset) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const devicesArr = (0, _Helpers_1.objectToArr)(((_c = (_b = (_a = asset === null || asset === void 0 ? void 0 : asset.heartbeat_report_json) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.devices_healthcheck) === null || _c === void 0 ? void 0 : _c.devices) || {}, 'device_name');
    const routerDevice = devicesArr === null || devicesArr === void 0 ? void 0 : devicesArr.find((device) => { var _a, _b; return ((_b = (_a = device === null || device === void 0 ? void 0 : device.device_type_info) === null || _a === void 0 ? void 0 : _a.router) === null || _b === void 0 ? void 0 : _b.location) !== undefined; });
    const lat = ((_f = (_e = (_d = routerDevice === null || routerDevice === void 0 ? void 0 : routerDevice.device_type_info) === null || _d === void 0 ? void 0 : _d.router) === null || _e === void 0 ? void 0 : _e.location) === null || _f === void 0 ? void 0 : _f.latitude) || '';
    const lng = ((_j = (_h = (_g = routerDevice === null || routerDevice === void 0 ? void 0 : routerDevice.device_type_info) === null || _g === void 0 ? void 0 : _g.router) === null || _h === void 0 ? void 0 : _h.location) === null || _j === void 0 ? void 0 : _j.longitude) || '';
    const loc = (0, exports.getGeoloationFromStr)(`${lat}, ${lng}`);
    if (loc)
        return loc;
    return null;
};
exports.getAssetGeolocation = getAssetGeolocation;
/**
 * Validates geolocation string, returns { lat, lng } if valid, null otherwise
 * @param str geolocation string
 * @returns null | { lat: number, lng: number }
 */
const getGeoloationFromStr = (str) => {
    const geo = str.split(',');
    if (geo.length !== 2)
        return null;
    const lat = parseFloat((0, exports.formatGeolocation)(geo[0].trim()));
    const lng = parseFloat((0, exports.formatGeolocation)(geo[1].trim()));
    if (Number.isNaN(lat) || Number.isNaN(lng) || lat < -90 || lat > 90 || lng < -180 || lng > 180)
        return null;
    return { lat, lng };
};
exports.getGeoloationFromStr = getGeoloationFromStr;
