"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const userInfoActions_1 = require("../actions/userInfoActions");
const defaultState = {
    id: 0,
    firstname: '',
    email: '',
    email_verified_at: '',
    created_at: '',
    updated_at: '',
    lastname: '',
    is_active: false,
    roles: [],
    last_action: null,
    timezone: null,
};
const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case userInfoActions_1.SET_USER_INFO:
            return Object.assign({}, action.payload);
        case userInfoActions_1.RESET_USER_INFO:
            return Object.assign({}, defaultState);
        default:
            return state;
    }
};
exports.default = userReducer;
