"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auditTrailAPI = exports.AUDIT_TRAIL_INFO = void 0;
const _Api_1 = require("@Api");
exports.AUDIT_TRAIL_INFO = 'AUDIT_TRAIL_INFO';
const auditTrailAPI = (id, model) => (0, _Api_1.api)('audit_trail', {
    action: exports.AUDIT_TRAIL_INFO,
    active_role: 10,
    fillable: {
        model,
        id,
    },
});
exports.auditTrailAPI = auditTrailAPI;
