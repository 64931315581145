"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetUserInfoAction = exports.RESET_USER_INFO = exports.setUserInfoAction = exports.SET_USER_INFO = void 0;
exports.SET_USER_INFO = 'SET_USER_INFO';
const setUserInfoAction = (user) => ({
    type: exports.SET_USER_INFO,
    payload: user,
});
exports.setUserInfoAction = setUserInfoAction;
exports.RESET_USER_INFO = 'RESET_USER_INFO';
const resetUserInfoAction = () => ({
    type: exports.RESET_USER_INFO,
});
exports.resetUserInfoAction = resetUserInfoAction;
