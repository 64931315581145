"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetTypeLookupAPI = void 0;
const _Api_1 = require("@Api");
const assetTypeLookupAPI = (search_term) => (0, _Api_1.api)('asset_type', {
    action: 'ASSET_TYPE_LOOKUP',
    fillable: {
        search_term,
    },
});
exports.assetTypeLookupAPI = assetTypeLookupAPI;
