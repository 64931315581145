"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appStatusActions_1 = require("../actions/appStatusActions");
const defaultState = {
    isGlobalLoading: false,
    isSideBarOpen: false,
};
const appStatusReducer = (state = defaultState, action) => {
    switch (action.type) {
        case appStatusActions_1.SET_GLOBAL_LOADER:
            return Object.assign(Object.assign({}, state), { isGlobalLoading: action.payload });
        case appStatusActions_1.RESET_GLOBAL_LOADER:
            return Object.assign(Object.assign({}, state), { isGlobalLoading: false });
        case appStatusActions_1.TOGGLE_SIDE_BAR:
            return Object.assign(Object.assign({}, state), { isSideBarOpen: !state.isSideBarOpen });
        default:
            return state;
    }
};
exports.default = appStatusReducer;
