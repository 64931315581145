"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const homeSearchActions_1 = require("@Store/actions/homeSearchActions");
const initialState = {
    basicSearch: [],
    advanceSearch: [],
    isWrittenOffAssets: false,
    isIncludeNotHireableAssets: false,
    isUserApplicableErrors: true,
};
const homeSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case homeSearchActions_1.SET_BASIC_SEARCH:
            return Object.assign(Object.assign({}, state), { basicSearch: action.payload });
        case homeSearchActions_1.SET_ADVANCE_SEARCH:
            return Object.assign(Object.assign({}, state), { advanceSearch: action.payload });
        case homeSearchActions_1.SET_WRITTEN_OFF_ASSETS:
            return Object.assign(Object.assign({}, state), { isWrittenOffAssets: action.payload });
        case homeSearchActions_1.SET_INCLUDE_NOT_HIREABLE_ASSETS:
            return Object.assign(Object.assign({}, state), { isIncludeNotHireableAssets: action.payload });
        case homeSearchActions_1.SET_USER_APPLICABLE_ERRORS:
            return Object.assign(Object.assign({}, state), { isUserApplicableErrors: action.payload });
        case homeSearchActions_1.CLEAR_HOME_SEARCH:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
exports.default = homeSearchReducer;
