"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiFactorValidateMFAApi = exports.multiFactorActivateMFAApi = void 0;
const _Api_1 = require("@Api");
const multiFactorActivateMFAApi = (mfa_code) => (0, _Api_1.api)('multi_factor', {
    action: 'AUTHMFA_ACTIVATE_MFA',
    mfa_code,
});
exports.multiFactorActivateMFAApi = multiFactorActivateMFAApi;
const multiFactorValidateMFAApi = (mfa_code) => (0, _Api_1.api)('multi_factor', {
    action: 'AUTHMFA_VALIDATE_MFA',
    mfa_code,
});
exports.multiFactorValidateMFAApi = multiFactorValidateMFAApi;
