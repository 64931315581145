"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.branchTasksResolveAPI = exports.BRANCH_TASKS_RESOLVE_ASSET_TRANSFER_ACTION = exports.branchTaskSearchFiltersAPI = exports.branchTasksListExportAPI = exports.branchTasksListAPI = exports.BRANCH_TASKS_LIST_ACTION = void 0;
const _Api_1 = require("@Api");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const index_1 = require("@Constants/index");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
exports.BRANCH_TASKS_LIST_ACTION = 'BRANCH_TASKS_LIST';
const branchTasksListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = index_1.ITEM_PER_PAGE) => (0, _Api_1.api)('branch_tasks', {
    action: exports.BRANCH_TASKS_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.branchTasksListAPI = branchTasksListAPI;
const branchTasksListExportAPI = (basic_search, advance_search, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC) => (0, _Api_1.api)('branch_tasks', {
    action: 'BRANCH_TASKS_LIST_EXPORT',
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    search: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.branchTasksListExportAPI = branchTasksListExportAPI;
const branchTaskSearchFiltersAPI = () => (0, _Api_1.api)('branch_tasks', {
    action: 'BRANCH_TASKS_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.branchTaskSearchFiltersAPI = branchTaskSearchFiltersAPI;
exports.BRANCH_TASKS_RESOLVE_ASSET_TRANSFER_ACTION = 'BRANCH_TASKS_RESOLVE_ASSET_TRANSFER';
const branchTasksResolveAPI = (task_id) => (0, _Api_1.api)('branch_tasks', {
    action: exports.BRANCH_TASKS_RESOLVE_ASSET_TRANSFER_ACTION,
    fillable: {
        task_id,
    },
});
exports.branchTasksResolveAPI = branchTasksResolveAPI;
