"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userProfileUpdateAPI = exports.USER_PROFILE_UPDATE_ACTION = exports.userUpdateAPI = exports.USER_UPDATE_ACTION = exports.userInsertAPI = exports.USER_INSERT_ACTION = exports.userResetMFAAPI = exports.USER_RESET_MFA_ACTION = exports.userResetPasswordAPI = exports.USER_RESET_PWD_ACTION = exports.userSearchFiltersAPI = exports.userListAPI = exports.USER_LIST_ACTION = exports.userChangePasswordAPI = exports.userNewEditAPI = void 0;
const _Api_1 = require("@Api");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const index_1 = require("@Constants/index");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const userNewEditAPI = (id) => (0, _Api_1.api)('user', {
    action: 'USER_NEW_EDIT',
    fillable: {
        user: {
            id: id || null,
        },
    },
});
exports.userNewEditAPI = userNewEditAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-User#action-change-password
const userChangePasswordAPI = (old_password, new_password) => (0, _Api_1.api)('user', {
    action: 'USER_CHG_PASSW',
    fillable: {
        user: {
            old_password,
            new_password,
        },
    },
});
exports.userChangePasswordAPI = userChangePasswordAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-User#action-list
exports.USER_LIST_ACTION = 'USER_LIST';
const userListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = index_1.ITEM_PER_PAGE) => (0, _Api_1.api)('user', {
    action: exports.USER_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.userListAPI = userListAPI;
const userSearchFiltersAPI = () => (0, _Api_1.api)('user', {
    action: 'USER_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.userSearchFiltersAPI = userSearchFiltersAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-User#action-reset-password
exports.USER_RESET_PWD_ACTION = 'USER_RESET_PWD';
const userResetPasswordAPI = (id) => (0, _Api_1.api)('user', {
    action: exports.USER_RESET_PWD_ACTION,
    fillable: {
        user: {
            id,
        },
    },
});
exports.userResetPasswordAPI = userResetPasswordAPI;
exports.USER_RESET_MFA_ACTION = 'USER_RESET_MFA';
const userResetMFAAPI = (id) => (0, _Api_1.api)('user', {
    action: exports.USER_RESET_MFA_ACTION,
    fillable: {
        user: {
            id,
        },
    },
});
exports.userResetMFAAPI = userResetMFAAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-User#action-insert
exports.USER_INSERT_ACTION = 'USER_INSERT';
const userInsertAPI = (user) => (0, _Api_1.api)('user', {
    action: exports.USER_INSERT_ACTION,
    fillable: {
        user,
    },
});
exports.userInsertAPI = userInsertAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-User#action-update
exports.USER_UPDATE_ACTION = 'USER_UPDATE';
const userUpdateAPI = (user) => (0, _Api_1.api)('user', {
    action: exports.USER_UPDATE_ACTION,
    fillable: {
        user,
    },
});
exports.userUpdateAPI = userUpdateAPI;
exports.USER_PROFILE_UPDATE_ACTION = 'USER_PROFILE_UPDATE';
const userProfileUpdateAPI = (user) => (0, _Api_1.api)('user', {
    action: exports.USER_PROFILE_UPDATE_ACTION,
    fillable: {
        user,
    },
});
exports.userProfileUpdateAPI = userProfileUpdateAPI;
