"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const interfaces_1 = require("@Interfaces/interfaces");
const GetCurrency = (countryName = '') => {
    switch (countryName.toUpperCase()) {
        case interfaces_1.CountryCode.AUSTRALIA:
            return 'AUD';
        case interfaces_1.CountryCode.NEWZEALAND:
            return 'NZD';
        default:
            return 'Unknown Currency';
    }
};
exports.default = GetCurrency;
