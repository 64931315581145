"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTouchDevice = exports.removeOuterWhitespace = exports.checkStringHasWhitespaceOnly = exports.checkIsJsonString = exports.environmentMatch = exports.isEmpty = exports.saveFile = exports.formatBasicSearchWithoutComma = exports.addIds2levels = exports.checkUserAllowed = exports.getCookie = exports.getEdgeDeploymentStatus = exports.getOverallStatusColor = exports.updateTemperatureStatus = exports.updatePeakVoltageStatus = exports.updateCurrentAndLowVoltageStatus = exports.getInfoStatusColor = exports.convertHeartBeatDateTime = exports.objectToArr = exports.makeNumArr = exports.packageArrToStr = exports.getAssetErrorStatusColor = exports.getAssetHealthStatusColor = exports.getActiveStatusColor = exports.getHireStatusColor = exports.capitalize = exports.getDeviceHealthStatusColor = exports.groupThemeColor = void 0;
const interfaces_1 = require("@Interfaces/interfaces");
const Theme_1 = __importStar(require("@Styles/Theme"));
const _Constants_1 = require("@Constants");
const groupThemeColor = (group) => {
    if (group === interfaces_1.RoleGroup.SUPERADMIN) {
        return Theme_1.visionPallete.visionPink;
    }
    return Theme_1.visionPallete.visionBlue;
};
exports.groupThemeColor = groupThemeColor;
const getDeviceHealthStatusColor = (status) => {
    switch (status) {
        case interfaces_1.HealthStatus.ONLINE:
            return Theme_1.visionPallete.visionGreen;
        default:
            return '#F44336';
    }
};
exports.getDeviceHealthStatusColor = getDeviceHealthStatusColor;
/**
 * Given a string => Uppercase first letter + rest in lowercase
 * @param str any string
 * @returns capitalised string
 */
const capitalize = (str = '') => {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
};
exports.capitalize = capitalize;
const getHireStatusColor = (hireStatus) => {
    if (!hireStatus) {
        return Theme_1.visionPallete.disabledGrey;
    }
    if ([interfaces_1.HireStatus.ON_HIRE, interfaces_1.HireStatus.USEDBYATF, interfaces_1.HireStatus.DEMO].includes(hireStatus)) {
        return Theme_1.visionPallete.visionGreen;
    }
    if (hireStatus.startsWith('OFF-HIRE')) {
        return Theme_1.visionPallete.visionGrey;
    }
    if ([interfaces_1.HireStatus.DAMAGED, interfaces_1.HireStatus.WOFF].includes(hireStatus)) {
        return Theme_1.default.palette.warning.main;
    }
    return Theme_1.visionPallete.visionYellow;
};
exports.getHireStatusColor = getHireStatusColor;
const getActiveStatusColor = (isActive) => isActive ? Theme_1.visionPallete.enabledGreen : Theme_1.visionPallete.disabledGrey;
exports.getActiveStatusColor = getActiveStatusColor;
const getAssetHealthStatusColor = (healthStatus) => {
    if (healthStatus === interfaces_1.HealthStatus.OFFLINE || healthStatus === interfaces_1.HealthStatus.CRITICAL)
        return Theme_1.default.palette.warning.main;
    if (healthStatus === interfaces_1.HealthStatus.HIGH)
        return Theme_1.default.palette.error.light;
    if (healthStatus === interfaces_1.HealthStatus.MEDIUM)
        return Theme_1.visionPallete.visionLightBlue;
    if (healthStatus === interfaces_1.HealthStatus.NORMAL || healthStatus === interfaces_1.HealthStatus.ONLINE)
        return Theme_1.visionPallete.visionGreen;
    return 'grey';
};
exports.getAssetHealthStatusColor = getAssetHealthStatusColor;
const getAssetErrorStatusColor = (status) => {
    if (status === interfaces_1.AssetErrorStatus.active)
        return Theme_1.visionPallete.priority.critical;
    if (status === interfaces_1.AssetErrorStatus.resolved)
        return Theme_1.visionPallete.visionGreen;
    return Theme_1.visionPallete.visionYellow;
};
exports.getAssetErrorStatusColor = getAssetErrorStatusColor;
const packageArrToStr = (packageArr) => {
    var _a;
    const str = (_a = packageArr === null || packageArr === void 0 ? void 0 : packageArr.map((pkg) => { var _a; return ` ${pkg === null || pkg === void 0 ? void 0 : pkg.package_name}_${(_a = pkg === null || pkg === void 0 ? void 0 : pkg.package_version) === null || _a === void 0 ? void 0 : _a.package_version_name}`; })) === null || _a === void 0 ? void 0 : _a.toString();
    return str;
};
exports.packageArrToStr = packageArrToStr;
/**
 * Given a length, returns a sequencial numeric array starting at 0
 * @param arrLength length of result array
 * @returns [0, 1, 2, ...(arrayLength-1)]
 */
const makeNumArr = (arrLength) => {
    const arr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrLength; i++) {
        arr.push(i);
    }
    return arr;
};
exports.makeNumArr = makeNumArr;
const objectToArr = (object, keyName) => {
    var _a;
    return typeof object === 'undefined' || object === null
        ? []
        : (_a = Object.entries(object)) === null || _a === void 0 ? void 0 : _a.map(([k, value]) => (Object.assign({ [keyName]: k }, value)));
};
exports.objectToArr = objectToArr;
const convertHeartBeatDateTime = (timeDateInfo) => {
    var _a, _b;
    const date = timeDateInfo.substring(0, 10);
    const formatedDate = (_b = (_a = date.split('-')) === null || _a === void 0 ? void 0 : _a.reverse()) === null || _b === void 0 ? void 0 : _b.join('/');
    const time = timeDateInfo.substring(11, 19);
    const formattedTimeDate = `${time} ${formatedDate}`;
    return formattedTimeDate;
};
exports.convertHeartBeatDateTime = convertHeartBeatDateTime;
const getInfoStatusColor = (infoName, infoType, value) => {
    if (value) {
        if (infoName === 'VOLTAGE') {
            if ((infoType === null || infoType === void 0 ? void 0 : infoType.toUpperCase()) === 'CURRENT' || (infoType === null || infoType === void 0 ? void 0 : infoType.toUpperCase()) === 'LOW24HR') {
                if (value > 12.2) {
                    return 'rgba(0,0,0,0.5)';
                }
                if (value <= 12.2 && value > 11.5) {
                    return '#FFBF00';
                }
                if (value <= 11.5) {
                    return 'red';
                }
                return 'rgba(0,0,0,0.5)';
            }
            if ((infoType === null || infoType === void 0 ? void 0 : infoType.toUpperCase()) === 'PEAK24HR') {
                if (value > 13) {
                    return 'rgba(0,0,0,0.5)';
                }
                if (value <= 13 && value > 12.5) {
                    return '#FFBF00';
                }
                if (value <= 12.5) {
                    return 'red';
                }
                return 'rgba(0,0,0,0.5)';
            }
        }
        if (infoName === 'TEMPERATURE') {
            if (value < 85) {
                return 'rgba(0,0,0,0.5)';
            }
            if (value <= 94 && value >= 85) {
                return '#FFBF00';
            }
            if (value > 94) {
                return 'red';
            }
        }
        return 'rgba(0,0,0,0.5)';
    }
    return 'rgba(0,0,0,0.5)';
};
exports.getInfoStatusColor = getInfoStatusColor;
const updateCurrentAndLowVoltageStatus = (voltageValue) => {
    if (!voltageValue)
        return 0;
    if (voltageValue > 12.2)
        return 1;
    if (voltageValue <= 12.2 && voltageValue > 11.5)
        return 2;
    if (voltageValue <= 11.5)
        return 3;
    return 0;
};
exports.updateCurrentAndLowVoltageStatus = updateCurrentAndLowVoltageStatus;
const updatePeakVoltageStatus = (voltageValue) => {
    if (!voltageValue)
        return 0;
    if (voltageValue > 13)
        return 1;
    if (voltageValue <= 13 && voltageValue > 12.5)
        return 2;
    if (voltageValue <= 12.5)
        return 3;
    return 0;
};
exports.updatePeakVoltageStatus = updatePeakVoltageStatus;
const updateTemperatureStatus = (temperatureValue) => {
    if (!temperatureValue)
        return 0;
    if (temperatureValue < 70)
        return 1;
    if (temperatureValue >= 70 && temperatureValue < 80)
        return 2;
    if (temperatureValue >= 80)
        return 3;
    return 0;
};
exports.updateTemperatureStatus = updateTemperatureStatus;
const getOverallStatusColor = (status) => {
    if (status === 1)
        return Theme_1.visionPallete.visionGreen;
    if (status === 2)
        return '#FFBF00';
    if (status === 3)
        return 'red';
    return 'rgba(0,0,0,0.5)';
};
exports.getOverallStatusColor = getOverallStatusColor;
/**
 * Gets status flags of edge deployment as well as hasCamera and hasDevices flag
 * @param assetDetail asset detail
 * @returns object with status flags
 */
const getEdgeDeploymentStatus = (assetDetail) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const hasDevices = (assetDetail.devices || []).length > 0;
    const hasCamera = (assetDetail.devices || []).filter((device) => _Constants_1.CAMERAS.includes(device.device_type_name)).length > 0;
    const edgeDeploymentStatus = (_c = (_b = (_a = assetDetail.heartbeat_report_json) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.deployment_healthcheck) === null || _c === void 0 ? void 0 : _c.overall_deployment_status;
    const edgeDeploymentStatusTooltip = assetDetail.heartbeat_report_json
        ? edgeDeploymentStatus
        : interfaces_1.DeploymentStatus.NOT_PROVIDED;
    const isEdgeDeploymentInstalling = (_d = edgeDeploymentStatus === null || edgeDeploymentStatus === void 0 ? void 0 : edgeDeploymentStatus.toUpperCase()) === null || _d === void 0 ? void 0 : _d.includes(interfaces_1.DeploymentStatus.INSTALLING || interfaces_1.DeploymentStatus.UPDATING);
    const isEdgeDeploymentRunning = (_e = edgeDeploymentStatus === null || edgeDeploymentStatus === void 0 ? void 0 : edgeDeploymentStatus.toUpperCase()) === null || _e === void 0 ? void 0 : _e.includes(interfaces_1.DeploymentStatus.RUNNING);
    const isEdgeDeploymentFailed = (_f = edgeDeploymentStatus === null || edgeDeploymentStatus === void 0 ? void 0 : edgeDeploymentStatus.toUpperCase()) === null || _f === void 0 ? void 0 : _f.includes(interfaces_1.DeploymentStatus.FAILED);
    const isEdgeDeploymentWarning = (_g = edgeDeploymentStatus === null || edgeDeploymentStatus === void 0 ? void 0 : edgeDeploymentStatus.toUpperCase()) === null || _g === void 0 ? void 0 : _g.includes(interfaces_1.DeploymentStatus.WARNING);
    const isEdgeDeploymentOther = !isEdgeDeploymentInstalling && !isEdgeDeploymentRunning && !isEdgeDeploymentFailed && !isEdgeDeploymentWarning;
    return {
        hasDevices,
        hasCamera,
        edgeDeploymentStatusTooltip,
        isEdgeDeploymentInstalling,
        isEdgeDeploymentRunning,
        isEdgeDeploymentFailed,
        isEdgeDeploymentWarning,
        isEdgeDeploymentOther,
    };
};
exports.getEdgeDeploymentStatus = getEdgeDeploymentStatus;
const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
exports.getCookie = getCookie;
/**
 * Returns if a user can do a specific restricted backend action
 * @param userStatus user status state
 * @param endpoint action endpoint
 * @param action action
 * @returns true if user is allowed to do action
 */
const checkUserAllowed = (userStatus, endpoint, action) => userStatus.permissions.some((p) => p.endpoint === endpoint && p.action === action);
exports.checkUserAllowed = checkUserAllowed;
/**
 * Adds sequencial id property to a 2 level array (first level: '1', '2', ..., second level: '1.1', '1.2', ...)
 * @param array [{ ... subLevel: [{ ... }, ... ]}, ... ]
 * @param subLevel sub level property name
 * @returns [{ id: '1' ... subLevel: [{ id: '1.1', ... }, ...]}, ... ]
 */
const addIds2levels = (subLevel, array) => array.map((el, i) => {
    var _a;
    return (Object.assign(Object.assign({ id: i + 1 }, el), { [subLevel]: (_a = el[subLevel]) === null || _a === void 0 ? void 0 : _a.map((sub, x) => (Object.assign({ id: `${i + 1}.${x + 1}` }, sub))) }));
});
exports.addIds2levels = addIds2levels;
/**
 * Basic Search formatter (split by comma and trim)
 * @param basicSearchTerms
 * @returns string[]
 */
const formatBasicSearchWithoutComma = (basicSearchTerms) => 
// check if string with a comma, remove white-space string
basicSearchTerms
    .map((term) => (term.indexOf(',') > -1 ? term.split(',').filter((tm) => /\S/.test(tm)) : term))
    .flat();
exports.formatBasicSearchWithoutComma = formatBasicSearchWithoutComma;
/**
 * Function to open dialog box to save file from blob
 * @param blob
 * @param nameOfFile
 */
const saveFile = (blob, nameOfFile) => __awaiter(void 0, void 0, void 0, function* () {
    const a = document.createElement('a');
    a.download = nameOfFile;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
});
exports.saveFile = saveFile;
/**
 * Returns if value is empty
 * @param value value to check
 * @returns true if empty
 */
const isEmpty = (value) => value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
exports.isEmpty = isEmpty;
/**
 * Return true if current environment matches
 * @param env environment to check ('prod' | 'dev' | 'local')
 * @returns
 */
const environmentMatch = (env) => {
    const { host } = window.location;
    if (env === interfaces_1.StatusTest.PROD && ['hub.visioni.net.au', 'prod-hub.developer.atfvision.net.au'].includes(host))
        return true;
    if (env === interfaces_1.StatusTest.DEV &&
        ['dev-portal.developer.atfvision.net.au', 'hub-dev_714e8068.visioni.network'].includes(host))
        return true;
    if (env === interfaces_1.StatusTest.LOCAL && host === 'localhost')
        return true;
    return false;
};
exports.environmentMatch = environmentMatch;
/**
 * Return true if string is JASON
 * @param str value to tcheck
 * @returns
 */
const checkIsJsonString = (str) => {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
exports.checkIsJsonString = checkIsJsonString;
/**
 * Return true if string has whitespace only
 * @param str value to check
 * @returns
 */
const checkStringHasWhitespaceOnly = (str) => !str.replace(/\s/g, '').length;
exports.checkStringHasWhitespaceOnly = checkStringHasWhitespaceOnly;
/**
 * Return string without outer whitespace, but keep whitespace between two terms like 'test site'
 * @param str value to check
 * @returns
 */
const removeOuterWhitespace = (str) => str.replace(/^\s+|\s+$/g, '');
exports.removeOuterWhitespace = removeOuterWhitespace;
/**
 * Returns true if device supports touch events
 */
const isTouchDevice = () => ('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0) || ((navigator.msMaxTouchPoints || 0) > 0);
exports.isTouchDevice = isTouchDevice;
