"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ASSET_MANAGEMENT_ROUTES = ['assets', 'customers', 'sites'];
const REPORTING_ROUTES = ['errors', 'outages'];
const GetHomeSearchRoute = (tabName) => {
    if (ASSET_MANAGEMENT_ROUTES.includes(tabName))
        return `/asset-management/${tabName}`;
    if (REPORTING_ROUTES.includes(tabName))
        return `/reporting/${tabName}`;
    return `/asset-management/assets`;
};
exports.default = GetHomeSearchRoute;
