"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserInfoAction = exports.logoutUserAction = exports.registerUserAction = void 0;
const _Api_1 = require("@Api");
const auth_1 = require("@Api/auth");
const appStatusActions_1 = require("@Store/actions/appStatusActions");
const userInfoActions_1 = require("@Store/actions/userInfoActions");
const userStatusActions_1 = require("@Store/actions/userStatusActions");
const alertActions_1 = require("@Store/actions/alertActions");
const interfaces_1 = require("@Interfaces/interfaces");
const registerUserAction = (data, onSuccess, onError) => (dispatch) => {
    dispatch({
        type: 'API',
        payload: {
            method: 'POST',
            url: '/api/users/register',
            data,
            success: () => { },
            postProcessSuccess: onSuccess,
            postProcessError: onError,
        },
    });
};
exports.registerUserAction = registerUserAction;
exports.logoutUserAction = _Api_1.logoutUserThunk;
const fetchUserInfoAction = (onSuccess, onFailure) => (dispatch) => {
    dispatch((0, appStatusActions_1.setGlobalLoaderAction)(true));
    (0, auth_1.authUserInfoAPI)()
        .then(({ auth_status, user, mfa_setup }) => {
        if (user)
            dispatch((0, userInfoActions_1.setUserInfoAction)(user));
        const defaultRole = user === null || user === void 0 ? void 0 : user.roles.find((r) => r.is_default);
        dispatch((0, userStatusActions_1.setUserStatusAction)({
            currentGroup: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.group.groupname) || interfaces_1.RoleGroup.UNDEFINED,
            currentRole: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.rolename) || interfaces_1.Role.UNDEFINED,
            currentRoleId: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.id) || 0,
            permissions: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.permissions) || [],
            roleAccess: {
                role_id: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.id) || 0,
                countries: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.countries) || [],
                branches: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.branches) || [],
                access_to_branches: (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.access_to_branches) || [],
            },
            authStatus: auth_status,
            mfa_setup: mfa_setup || undefined,
            isUnauthenticatedError: false,
            isUserIdle: false,
            isMaintenance: false,
        }));
        if (onSuccess)
            onSuccess();
    })
        .catch((error) => {
        var _a;
        const status = ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) || 0;
        if (status === 503) {
            dispatch((0, userStatusActions_1.setIsMaintenanceAction)(true));
        }
        if (onFailure)
            onFailure();
        if (![401, 503].includes(status)) {
            dispatch((0, alertActions_1.errorAlertAction)('Unable to fetch logged in user. Please try again later.'));
        }
        if (status === 401) {
            dispatch((0, exports.logoutUserAction)());
        }
    })
        .finally(() => dispatch((0, appStatusActions_1.setGlobalLoaderAction)(false)));
};
exports.fetchUserInfoAction = fetchUserInfoAction;
