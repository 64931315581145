"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetTransfersChangeAPI = exports.ASSET_TRANSFERS_CHANGE_ACTION = exports.assetTransfersDeleteAPI = exports.ASSET_TRANSFERS_DELETE_ACTION = exports.assetTransfersInsertAPI = exports.ASSET_TRANSFERS_INSERT_ACTION = exports.assetTransfersSearchFiltersAPI = exports.assetTransfersListAPI = exports.ASSET_TRANSFERS_LIST_ACTION = void 0;
const _Api_1 = require("@Api");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const index_1 = require("@Constants/index");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
exports.ASSET_TRANSFERS_LIST_ACTION = 'ASSET_TRANSFERS_LIST';
const assetTransfersListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = index_1.ITEM_PER_PAGE) => (0, _Api_1.api)('asset_transfers', {
    action: exports.ASSET_TRANSFERS_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.assetTransfersListAPI = assetTransfersListAPI;
const assetTransfersSearchFiltersAPI = () => (0, _Api_1.api)('asset_transfers', {
    action: 'ASSET_TRANSFERS_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.assetTransfersSearchFiltersAPI = assetTransfersSearchFiltersAPI;
exports.ASSET_TRANSFERS_INSERT_ACTION = 'ASSET_TRANSFERS_INSERT';
const assetTransfersInsertAPI = (fillable) => (0, _Api_1.api)('asset_transfers', {
    action: exports.ASSET_TRANSFERS_INSERT_ACTION,
    fillable,
});
exports.assetTransfersInsertAPI = assetTransfersInsertAPI;
exports.ASSET_TRANSFERS_DELETE_ACTION = 'ASSET_TRANSFERS_DELETE';
const assetTransfersDeleteAPI = (task_id) => (0, _Api_1.api)('asset_transfers', {
    action: exports.ASSET_TRANSFERS_DELETE_ACTION,
    fillable: {
        task_id,
    },
});
exports.assetTransfersDeleteAPI = assetTransfersDeleteAPI;
exports.ASSET_TRANSFERS_CHANGE_ACTION = 'ASSET_TRANSFERS_CHANGE';
const assetTransfersChangeAPI = (fillable) => (0, _Api_1.api)('asset_transfers', {
    action: exports.ASSET_TRANSFERS_CHANGE_ACTION,
    fillable,
});
exports.assetTransfersChangeAPI = assetTransfersChangeAPI;
