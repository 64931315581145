"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetFootageRequestAPI = exports.ASSET_FOOTAGE_REQUEST_ACTION = exports.assetCancelHealthCheckRequestAPI = exports.ASSET_CANCEL_HEALTH_CHECK_REQUEST_ACTION = exports.assetHealthCheckRequestAPI = exports.ASSET_HEALTH_CHECK_REQUEST_ACTION = exports.assetUpdateAPI = exports.ASSET_UPDATE_ACTION = exports.assetInsertAPI = exports.ASSET_INSERT_ACTION = exports.assetAlarmSettingsAPI = exports.ASSET_UPDATE_ALARM_SETTINGS = exports.assetLookupApi = exports.assetUpdateNotesAPI = exports.ASSET_UPDATE_NOTES_ACTION = exports.assetErrorHistoryAPI = exports.assetUpdateSettingsAPI = exports.ASSET_UPDATE_SETTINGS_ACTION = exports.assetHealthTelemetryAPI = exports.ASSET_TELEMETRY_ACTION = exports.assetListFiltersAPI = exports.assetUpdateDeploymentAPI = exports.ASSET_UPDATE_DEPLOYMENT_ACTION = exports.assetEditAPI = exports.assetCheckDiagnostiscAPI = exports.assetCleanDiagnosticAPI = exports.ASSET_CLEAN_DIAGNOSTICS_ACTION = exports.assetDiagnosticAPI = exports.ASSET_DIAGNOSTICS_ACTION = exports.assetInfoAPI = exports.assetListExportAPI = exports.assetListAPI = exports.ASSET_LIST_ACTION = void 0;
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const _Constants_1 = require("@Constants");
const _Api_1 = require("@Api");
exports.ASSET_LIST_ACTION = 'ASSET_LIST';
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Asset#action-list
const assetListAPI = (written_off, include_not_hireable, basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        written_off,
        include_not_hireable,
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.assetListAPI = assetListAPI;
const assetListExportAPI = (written_off, include_not_hireable, basic_search, advance_search, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC) => (0, _Api_1.api)('asset', {
    action: 'ASSET_LIST_EXPORT',
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        written_off,
        include_not_hireable,
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.assetListExportAPI = assetListExportAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Asset#action-info
const assetInfoAPI = (id) => (0, _Api_1.api)('asset', {
    action: 'ASSET_INFO',
    fillable: {
        asset: {
            id,
        },
    },
});
exports.assetInfoAPI = assetInfoAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Asset#action-diagnostics
exports.ASSET_DIAGNOSTICS_ACTION = 'ASSET_DIAGNOSTICS';
const assetDiagnosticAPI = (id) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_DIAGNOSTICS_ACTION,
    fillable: {
        asset: {
            id,
        },
    },
});
exports.assetDiagnosticAPI = assetDiagnosticAPI;
exports.ASSET_CLEAN_DIAGNOSTICS_ACTION = 'ASSET_CLEAN_DIAGNOSTICS';
const assetCleanDiagnosticAPI = (id) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_CLEAN_DIAGNOSTICS_ACTION,
    fillable: {
        asset: {
            id,
        },
    },
});
exports.assetCleanDiagnosticAPI = assetCleanDiagnosticAPI;
const assetCheckDiagnostiscAPI = (assetIds) => (0, _Api_1.api)('asset', {
    action: 'ASSET_CHECK_DIAGNOSTICS',
    fillable: {
        asset: {
            ids: assetIds,
        },
    },
});
exports.assetCheckDiagnostiscAPI = assetCheckDiagnostiscAPI;
const assetEditAPI = (id) => (0, _Api_1.api)('asset', {
    action: 'ASSET_EDIT',
    fillable: {
        asset: {
            id,
        },
    },
}).then((response) => response.fillable);
exports.assetEditAPI = assetEditAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Asset#action-update
exports.ASSET_UPDATE_DEPLOYMENT_ACTION = 'ASSET_UPDATE_DEPLOYMENT';
const assetUpdateDeploymentAPI = (id, deployment_stage_id, force_agent_install = false, force_update_packages = false) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_UPDATE_DEPLOYMENT_ACTION,
    fillable: {
        asset: {
            id,
            deployment_stage_id,
        },
    },
    force_agent_install,
    force_update_packages,
});
exports.assetUpdateDeploymentAPI = assetUpdateDeploymentAPI;
const assetListFiltersAPI = () => (0, _Api_1.api)('asset', {
    action: 'ASSET_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.assetListFiltersAPI = assetListFiltersAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Asset#action-asset_telemetry
exports.ASSET_TELEMETRY_ACTION = 'ASSET_TELEMETRY';
const assetHealthTelemetryAPI = (asset, start_date, end_date) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_TELEMETRY_ACTION,
    fillable: {
        asset,
        start_date,
        end_date,
    },
});
exports.assetHealthTelemetryAPI = assetHealthTelemetryAPI;
exports.ASSET_UPDATE_SETTINGS_ACTION = 'ASSET_UPDATE_SETTINGS';
const assetUpdateSettingsAPI = (asset_id, settingsKey, settingValues) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_UPDATE_SETTINGS_ACTION,
    fillable: { asset: { id: asset_id, settings: { [settingsKey]: settingValues } } },
});
exports.assetUpdateSettingsAPI = assetUpdateSettingsAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/
// API-Asset#action-asset_list_errors_history
const assetErrorHistoryAPI = (id) => (0, _Api_1.api)('asset', {
    action: 'ASSET_LIST_ERRORS_HISTORY',
    fillable: {
        asset: {
            id,
        },
    },
});
exports.assetErrorHistoryAPI = assetErrorHistoryAPI;
exports.ASSET_UPDATE_NOTES_ACTION = 'ASSET_UPDATE_NOTES';
const assetUpdateNotesAPI = (id, notes) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_UPDATE_NOTES_ACTION,
    fillable: {
        asset: {
            id,
            notes,
        },
    },
});
exports.assetUpdateNotesAPI = assetUpdateNotesAPI;
const assetLookupApi = (term, branch_id = null, include_not_hireable = false) => (0, _Api_1.api)('asset', {
    action: 'ASSET_LOOKUP',
    fillable: { search_term: term, branch_id, include_not_hireable },
});
exports.assetLookupApi = assetLookupApi;
exports.ASSET_UPDATE_ALARM_SETTINGS = 'ASSET_UPDATE_ALARM_SETTINGS';
const assetAlarmSettingsAPI = (id, alarm_enabled, siren_enabled) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_UPDATE_ALARM_SETTINGS,
    fillable: {
        asset: {
            id,
            alarm_enabled,
            siren_enabled,
        },
    },
});
exports.assetAlarmSettingsAPI = assetAlarmSettingsAPI;
exports.ASSET_INSERT_ACTION = 'ASSET_INSERT';
const assetInsertAPI = (asset) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_INSERT_ACTION,
    fillable: {
        asset,
    },
});
exports.assetInsertAPI = assetInsertAPI;
exports.ASSET_UPDATE_ACTION = 'ASSET_UPDATE';
const assetUpdateAPI = (asset) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_UPDATE_ACTION,
    fillable: {
        asset,
    },
});
exports.assetUpdateAPI = assetUpdateAPI;
exports.ASSET_HEALTH_CHECK_REQUEST_ACTION = 'ASSET_HEALTH_CHECK_REQUEST';
const assetHealthCheckRequestAPI = (id) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_HEALTH_CHECK_REQUEST_ACTION,
    fillable: {
        asset: { id },
    },
});
exports.assetHealthCheckRequestAPI = assetHealthCheckRequestAPI;
exports.ASSET_CANCEL_HEALTH_CHECK_REQUEST_ACTION = 'ASSET_CANCEL_HEALTH_CHECK_REQUEST';
const assetCancelHealthCheckRequestAPI = (id) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_CANCEL_HEALTH_CHECK_REQUEST_ACTION,
    fillable: {
        asset: { id },
    },
});
exports.assetCancelHealthCheckRequestAPI = assetCancelHealthCheckRequestAPI;
exports.ASSET_FOOTAGE_REQUEST_ACTION = 'ASSET_FOOTAGE_REQUEST';
const assetFootageRequestAPI = (id, request) => (0, _Api_1.api)('asset', {
    action: exports.ASSET_FOOTAGE_REQUEST_ACTION,
    fillable: {
        asset: { id },
        request,
    },
});
exports.assetFootageRequestAPI = assetFootageRequestAPI;
