"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearAlertMessagesAction = exports.CLEAR_ALERT_MESSAGES = exports.removeAlertAction = exports.REMOVE_ALERT = exports.warningAlertAction = exports.WARNING_ALERT = exports.infoAlertAction = exports.INFO_ALERT = exports.successAlertAction = exports.SUCCESS_ALERT = exports.errorAlertAction = exports.ERROR_ALERT = void 0;
exports.ERROR_ALERT = 'ERROR_ALERT';
const errorAlertAction = (message) => ({
    type: exports.ERROR_ALERT,
    payload: message,
});
exports.errorAlertAction = errorAlertAction;
exports.SUCCESS_ALERT = 'SUCCESS_ALERT';
const successAlertAction = (message) => ({
    type: exports.SUCCESS_ALERT,
    payload: message,
});
exports.successAlertAction = successAlertAction;
exports.INFO_ALERT = 'INFO_ALERT';
const infoAlertAction = (message) => ({
    type: exports.INFO_ALERT,
    payload: message,
});
exports.infoAlertAction = infoAlertAction;
exports.WARNING_ALERT = 'WARNING_ALERT';
const warningAlertAction = (message) => ({
    type: exports.WARNING_ALERT,
    payload: message,
});
exports.warningAlertAction = warningAlertAction;
exports.REMOVE_ALERT = 'REMOVE_ALERT';
const removeAlertAction = (id) => ({
    type: exports.REMOVE_ALERT,
    payload: id,
});
exports.removeAlertAction = removeAlertAction;
exports.CLEAR_ALERT_MESSAGES = 'CLEAR_ALERT_MESSAGES';
const clearAlertMessagesAction = () => ({
    type: exports.CLEAR_ALERT_MESSAGES,
});
exports.clearAlertMessagesAction = clearAlertMessagesAction;
