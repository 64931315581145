"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const GetRectangularPos = (known) => {
    const width = Math.abs(known[2] - known[0]);
    const height = Math.abs(known[3] - known[1]);
    const isUpright = height >= width;
    const signX = Math.sign(known[2] - known[0]);
    const signY = Math.sign(known[3] - known[1]);
    const x3 = known[0] + (isUpright ? signX * width : 0);
    const y3 = known[1] + (isUpright ? 0 : signY * height);
    const x4 = known[2] - (isUpright ? signX * width : 0);
    const y4 = known[3] - (isUpright ? 0 : signY * height);
    return [
        [known[0], known[1]],
        [x3, y3],
        [known[2], known[3]],
        [x4, y4],
    ];
};
exports.default = GetRectangularPos;
