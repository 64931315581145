"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chargedReasonLookupApi = exports.chargedReasonInfoApi = void 0;
const _1 = require(".");
const chargedReasonInfoApi = (id) => (0, _1.api)('charged_reason', {
    action: 'CHARGED_REASON_INFO',
    fillable: { charged_reason: { id } },
});
exports.chargedReasonInfoApi = chargedReasonInfoApi;
const chargedReasonLookupApi = (term) => (0, _1.api)('charged_reason', {
    action: 'CHARGED_REASON_LOOKUP',
    fillable: { search_term: term },
});
exports.chargedReasonLookupApi = chargedReasonLookupApi;
