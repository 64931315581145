"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorTransferAPI = exports.ASSET_ERROR_TRANSFER_ACTION = exports.errorTransferLookupsAPI = exports.ASSET_ERROR_TRANSFER_LOOKUPS_ACTION = exports.errorResolveAPI = exports.ASSET_ERROR_RESOLVE_ERROR_ACTION = exports.errorHistoryAPI = exports.ASSET_ERROR_HISTORY_ACTION = exports.errorInfoAPI = exports.ASSET_ERROR_INFO_ACTION = exports.assetErrorFiltersAPI = exports.assetErrorListExportAPI = exports.ASSET_ERROR_LIST_EXPORT_ACTION = exports.assetErrorListAPI = exports.ASSET_ERROR_LIST_ACTION = void 0;
const _Constants_1 = require("@Constants");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const _1 = require(".");
exports.ASSET_ERROR_LIST_ACTION = 'ASSET_ERROR_LIST';
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Asset-Error#action-search
const assetErrorListAPI = (user_applicable, basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
        user_applicable,
    },
});
exports.assetErrorListAPI = assetErrorListAPI;
exports.ASSET_ERROR_LIST_EXPORT_ACTION = 'ASSET_ERROR_LIST_EXPORT';
const assetErrorListExportAPI = (user_applicable, basic_search, advance_search, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_LIST_EXPORT_ACTION,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
        user_applicable,
    },
});
exports.assetErrorListExportAPI = assetErrorListExportAPI;
const assetErrorFiltersAPI = () => (0, _1.api)('asset_error', {
    action: 'ASSET_ERROR_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.assetErrorFiltersAPI = assetErrorFiltersAPI;
exports.ASSET_ERROR_INFO_ACTION = 'ASSET_ERROR_INFO';
const errorInfoAPI = (errorId) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_INFO_ACTION,
    fillable: {
        asset_error: {
            id: errorId,
        },
    },
});
exports.errorInfoAPI = errorInfoAPI;
exports.ASSET_ERROR_HISTORY_ACTION = 'ASSET_ERROR_HISTORY';
const errorHistoryAPI = (errorId, page) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_HISTORY_ACTION,
    fillable: {
        asset_error: {
            id: errorId,
        },
    },
    page,
    items_per_page: 200,
});
exports.errorHistoryAPI = errorHistoryAPI;
exports.ASSET_ERROR_RESOLVE_ERROR_ACTION = 'ASSET_ERROR_RESOLVE_ERROR';
const errorResolveAPI = (errorId) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_RESOLVE_ERROR_ACTION,
    fillable: {
        asset_error: { id: errorId },
    },
});
exports.errorResolveAPI = errorResolveAPI;
exports.ASSET_ERROR_TRANSFER_LOOKUPS_ACTION = 'ASSET_ERROR_TRANSFER_LOOKUPS';
const errorTransferLookupsAPI = (errorId) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_TRANSFER_LOOKUPS_ACTION,
    fillable: {
        asset_error: { id: errorId },
    },
});
exports.errorTransferLookupsAPI = errorTransferLookupsAPI;
exports.ASSET_ERROR_TRANSFER_ACTION = 'ASSET_ERROR_TRANSFER';
const errorTransferAPI = (errorId, roleGroupId, reason) => (0, _1.api)('asset_error', {
    action: exports.ASSET_ERROR_TRANSFER_ACTION,
    fillable: {
        asset_error: { id: errorId, role_group_id: roleGroupId, reason },
    },
});
exports.errorTransferAPI = errorTransferAPI;
