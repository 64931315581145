"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.siteTransferHiresAPI = exports.SITE_TRANSFER_HIRES_ACTION = exports.siteImageUrlAPI = exports.SITE_IMAGE_URL_ACTION = exports.siteImageDeleteAPI = exports.SITE_IMAGE_DELETE_ACTION = exports.siteImageUploadAPI = exports.SITE_IMAGE_UPLOAD_ACTION = exports.siteGroupDeleteAPI = exports.SITE_GROUP_DELETE_ACTION = exports.siteGroupUpdateAPI = exports.SITE_GROUP_UPDATE_ACTION = exports.siteGroupInsertAPI = exports.SITE_GROUP_INSERT_ACTION = exports.siteContactDeleteAPI = exports.SITE_CONTACT_DELETE_ACTION = exports.siteContactUpdateAPI = exports.SITE_CONTACT_UPDATE_ACTION = exports.siteContactInsertAPI = exports.SITE_CONTACT_INSERT_ACTION = exports.siteContractHistoryAPI = exports.SITE_CONTRACT_HISTORY_ACTION = exports.siteContractDeleteAPI = exports.SITE_CONTRACT_DELETE_ACTION = exports.siteContractInsertAPI = exports.SITE_CONTRACT_INSERT_ACTION = exports.siteUpdateAPI = exports.SITE_UPDATE_ACTION = exports.siteInsertAPI = exports.SITE_INSERT_ACTION = exports.siteListFiltersAPI = exports.siteListAPI = exports.SITE_LIST_ACTION = exports.siteInfoAPI = exports.SITE_INFO_ACTION = void 0;
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
const _Constants_1 = require("@Constants");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const _1 = require(".");
exports.SITE_INFO_ACTION = 'SITE_INFO';
const siteInfoAPI = (site_id) => (0, _1.api)('site', {
    action: exports.SITE_INFO_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
    },
});
exports.siteInfoAPI = siteInfoAPI;
exports.SITE_LIST_ACTION = 'SITE_LIST';
const siteListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _1.api)('site', {
    action: exports.SITE_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    search: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.siteListAPI = siteListAPI;
const siteListFiltersAPI = () => (0, _1.api)('site', {
    action: 'SITE_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.siteListFiltersAPI = siteListFiltersAPI;
exports.SITE_INSERT_ACTION = 'SITE_INSERT';
const siteInsertAPI = (siteFields) => (0, _1.api)('site', {
    action: exports.SITE_INSERT_ACTION,
    fillable: {
        site: Object.assign({}, siteFields),
    },
});
exports.siteInsertAPI = siteInsertAPI;
exports.SITE_UPDATE_ACTION = 'SITE_UPDATE';
const siteUpdateAPI = (site_id, siteFields) => (0, _1.api)('site', {
    action: exports.SITE_UPDATE_ACTION,
    fillable: {
        site: Object.assign({ id: site_id }, siteFields),
    },
});
exports.siteUpdateAPI = siteUpdateAPI;
exports.SITE_CONTRACT_INSERT_ACTION = 'SITE_CONTRACT_INSERT';
const siteContractInsertAPI = (site_id, contract_number) => (0, _1.api)('site', {
    action: exports.SITE_CONTRACT_INSERT_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        contract: {
            contract_number,
        },
    },
});
exports.siteContractInsertAPI = siteContractInsertAPI;
exports.SITE_CONTRACT_DELETE_ACTION = 'SITE_CONTRACT_DELETE';
const siteContractDeleteAPI = (site_id, contract_id) => (0, _1.api)('site', {
    action: exports.SITE_CONTRACT_DELETE_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        contract: {
            id: contract_id,
        },
    },
});
exports.siteContractDeleteAPI = siteContractDeleteAPI;
exports.SITE_CONTRACT_HISTORY_ACTION = 'SITE_CONTRACT_HISTORY';
const siteContractHistoryAPI = (site_id) => (0, _1.api)('site', {
    action: exports.SITE_CONTRACT_HISTORY_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
    },
});
exports.siteContractHistoryAPI = siteContractHistoryAPI;
exports.SITE_CONTACT_INSERT_ACTION = 'SITE_CONTACT_INSERT';
const siteContactInsertAPI = (site_id, site_contact) => (0, _1.api)('site', {
    action: exports.SITE_CONTACT_INSERT_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        site_contact,
    },
});
exports.siteContactInsertAPI = siteContactInsertAPI;
exports.SITE_CONTACT_UPDATE_ACTION = 'SITE_CONTACT_UPDATE';
const siteContactUpdateAPI = (site_id, site_contact) => (0, _1.api)('site', {
    action: exports.SITE_CONTACT_UPDATE_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        site_contact,
    },
});
exports.siteContactUpdateAPI = siteContactUpdateAPI;
exports.SITE_CONTACT_DELETE_ACTION = 'SITE_CONTACT_DELETE';
const siteContactDeleteAPI = (site_id, contact_id) => (0, _1.api)('site', {
    action: exports.SITE_CONTACT_DELETE_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        site_contact: {
            contact_id,
        },
    },
});
exports.siteContactDeleteAPI = siteContactDeleteAPI;
exports.SITE_GROUP_INSERT_ACTION = 'SITE_GROUP_INSERT';
const siteGroupInsertAPI = (site_id, group) => (0, _1.api)('site', {
    action: exports.SITE_GROUP_INSERT_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        group,
    },
});
exports.siteGroupInsertAPI = siteGroupInsertAPI;
exports.SITE_GROUP_UPDATE_ACTION = 'SITE_GROUP_UPDATE';
const siteGroupUpdateAPI = (site_id, group) => (0, _1.api)('site', {
    action: exports.SITE_GROUP_UPDATE_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        group,
    },
});
exports.siteGroupUpdateAPI = siteGroupUpdateAPI;
exports.SITE_GROUP_DELETE_ACTION = 'SITE_GROUP_DELETE';
const siteGroupDeleteAPI = (site_id, group_id) => (0, _1.api)('site', {
    action: exports.SITE_GROUP_DELETE_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        group: {
            id: group_id,
        },
    },
});
exports.siteGroupDeleteAPI = siteGroupDeleteAPI;
exports.SITE_IMAGE_UPLOAD_ACTION = 'SITE_IMAGE_UPLOAD';
const siteImageUploadAPI = (site_id, filename, file, abortController, progressHandler // Progress handler (number 0-100)
) => (0, _1.api)('site', {
    action: exports.SITE_IMAGE_UPLOAD_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        s3_document: {
            filename,
        },
    },
}, undefined, abortController, true, true, file, progressHandler);
exports.siteImageUploadAPI = siteImageUploadAPI;
exports.SITE_IMAGE_DELETE_ACTION = 'SITE_IMAGE_DELETE';
const siteImageDeleteAPI = (site_id, doc_id) => (0, _1.api)('site', {
    action: exports.SITE_IMAGE_DELETE_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        s3_document: {
            id: doc_id,
        },
    },
});
exports.siteImageDeleteAPI = siteImageDeleteAPI;
exports.SITE_IMAGE_URL_ACTION = 'SITE_IMAGE_URL';
const siteImageUrlAPI = (site_id, doc_id) => (0, _1.api)('site', {
    action: exports.SITE_IMAGE_URL_ACTION,
    fillable: {
        site: {
            id: site_id,
        },
        s3_document: {
            id: doc_id,
        },
    },
});
exports.siteImageUrlAPI = siteImageUrlAPI;
exports.SITE_TRANSFER_HIRES_ACTION = 'SITE_TRANSFER_HIRES';
const siteTransferHiresAPI = (site_id, target_group_id, hire_ids) => (0, _1.api)('site', {
    action: exports.SITE_TRANSFER_HIRES_ACTION,
    fillable: {
        site: {
            id: site_id,
            target_group_id,
            hire_ids,
        },
    },
});
exports.siteTransferHiresAPI = siteTransferHiresAPI;
