"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monitoringProviderLookupApi = exports.monitoringProviderInfoApi = void 0;
const _1 = require(".");
const monitoringProviderInfoApi = (id) => (0, _1.api)('monitoring_provider', {
    action: 'MONITORING_PROVIDER_INFO',
    fillable: { monitoring_provider: { id } },
});
exports.monitoringProviderInfoApi = monitoringProviderInfoApi;
const monitoringProviderLookupApi = (term) => (0, _1.api)('monitoring_provider', {
    action: 'MONITORING_PROVIDER_LOOKUP',
    fillable: { search_term: term },
});
exports.monitoringProviderLookupApi = monitoringProviderLookupApi;
