"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAuditTrailInfoAPIResp = exports.stringifyUnknownValue = void 0;
/**
 * Returns the string version of an unkown value. Json is prettyfied.
 * @param value unknown value
 * @returns string version of value
 */
const stringifyUnknownValue = (value) => {
    if (value === undefined)
        return 'N/A';
    if (value === null)
        return 'null';
    if (['boolean', 'number', 'bigint', 'symbol'].includes(typeof value)) {
        return String(value);
    }
    if (typeof value === 'object') {
        try {
            return JSON.stringify(value, null, 2);
        }
        catch (e) {
            return String(value);
        }
    }
    if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
        try {
            return JSON.stringify(JSON.parse(value), null, 2);
        }
        catch (e) {
            return String(value);
        }
    }
    return String(value);
};
exports.stringifyUnknownValue = stringifyUnknownValue;
/**
 * Formats audit trail info from the BE breaking down changes by field.
 * @param response BE audit trail response
 * @returns formatted list of audit trail info
 */
const formatAuditTrailInfoAPIResp = (response) => response.map((resp) => {
    const oldValues = Array.isArray(resp.old_values) ? {} : resp.old_values;
    const newValues = Array.isArray(resp.new_values) ? {} : resp.new_values;
    const allKeys = Object.keys(Object.assign(Object.assign({}, oldValues), newValues));
    const changes = allKeys.map((key) => ({
        fieldName: key,
        oldValue: (0, exports.stringifyUnknownValue)(oldValues[key]),
        newValue: (0, exports.stringifyUnknownValue)(newValues[key]),
    }));
    const item = {
        id: resp.id,
        user: resp.user,
        timeStamp: resp.created_at,
        changes,
        actionEvent: resp.event,
    };
    return item;
});
exports.formatAuditTrailInfoAPIResp = formatAuditTrailInfoAPIResp;
