"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const diagnosticsActions_1 = require("@Store/actions/diagnosticsActions");
const interfaces_1 = require("@Interfaces/interfaces");
const defaultState = {
    toRunDiagnostics: [],
    runningDiagnostics: [],
    finishedDiagnosticAssets: [],
};
const assetReducer = (state = defaultState, action) => {
    switch (action.type) {
        case diagnosticsActions_1.ADD_TO_RUN_DIAGNOSTIC:
            if ([...state.toRunDiagnostics, ...state.runningDiagnostics].includes(action.payload)) {
                return state;
            }
            return Object.assign(Object.assign({}, state), { toRunDiagnostics: [...state.toRunDiagnostics, action.payload] });
        case diagnosticsActions_1.REMOVE_TO_RUN_DIAGNOSTIC:
            return Object.assign(Object.assign({}, state), { toRunDiagnostics: state.toRunDiagnostics.filter(id => id !== action.payload) });
        case diagnosticsActions_1.ADD_RUNNING_DIAGNOSTICS:
            return Object.assign(Object.assign({}, state), { toRunDiagnostics: state.toRunDiagnostics.filter(id => !action.payload.includes(id)), runningDiagnostics: [
                    ...state.runningDiagnostics.filter(id => !action.payload.includes(id)),
                    ...action.payload,
                ] });
        case diagnosticsActions_1.CHECK_DIAGNOSTICS:
            return Object.assign(Object.assign({}, state), { 
                // Remove the ones that are not running anymore
                runningDiagnostics: state.runningDiagnostics
                    .filter(id => !action.payload
                    .filter(dr => dr.status !== interfaces_1.DiagnosticStatus.RUNNING).map(dr => dr.asset_id).includes(id)), 
                // Add succesful asset details
                finishedDiagnosticAssets: [
                    ...state.finishedDiagnosticAssets,
                    ...action.payload
                        .filter(dr => dr.status === interfaces_1.DiagnosticStatus.SUCCESS && dr.asset_detail !== null)
                        .map(a => a.asset_detail),
                ] });
        case diagnosticsActions_1.CLEANUP_DIAGNOSTICS:
            return Object.assign(Object.assign({}, state), { finishedDiagnosticAssets: [] });
        default:
            return state;
    }
};
exports.default = assetReducer;
