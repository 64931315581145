"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimezoneByPostcode = exports.getTimezoneStateOpts = exports.getTimezoneOpts = exports.getStateOptions = void 0;
const interfaces_1 = require("@Interfaces/interfaces");
const getStateOptions = (countryCode) => {
    switch (countryCode) {
        case interfaces_1.CountryCode.AUSTRALIA: // https://en.wikipedia.org/wiki/ISO_3166-2:AU
            return [
                {
                    value: 'NSW',
                    label: 'New South Wales',
                },
                {
                    value: 'VIC',
                    label: 'Victoria',
                },
                {
                    value: 'QLD',
                    label: 'Queensland',
                },
                {
                    value: 'SA',
                    label: 'South Australia',
                },
                {
                    value: 'WA',
                    label: 'Western Australia',
                },
                {
                    value: 'NT',
                    label: 'Northern Territory',
                },
                {
                    value: 'TAS',
                    label: 'Tasmania',
                },
                {
                    value: 'ACT',
                    label: 'Australian Capital Territory',
                },
            ];
        case interfaces_1.CountryCode.NEWZEALAND: // https://en.wikipedia.org/wiki/ISO_3166-2:NZ
            return [
                {
                    value: 'AUK',
                    label: 'Auckland',
                    tooltip: 'Tāmaki-makau-rau',
                },
                {
                    value: 'BOP',
                    label: 'Bay of Plenty',
                    tooltip: 'Te Moana a Toi Te Huatahi',
                },
                {
                    value: 'CAN',
                    label: 'Canterbury',
                    tooltip: 'Waitaha',
                },
                {
                    value: 'GIS',
                    label: 'Gisborne',
                    tooltip: 'Tūranga nui a Kiwa',
                },
                {
                    value: 'HKB',
                    label: "Hawke's Bay",
                    tooltip: 'Te Matau a Māui',
                },
                {
                    value: 'MBH',
                    label: 'Marlborough',
                    tooltip: 'Marlborough',
                },
                {
                    value: 'MWT',
                    label: 'Manawatu-Wanganui',
                    tooltip: 'Manawatu Whanganui',
                },
                {
                    value: 'NSN',
                    label: 'Nelson',
                    tooltip: 'Whakatū',
                },
                {
                    value: 'NTL',
                    label: 'Northland',
                    tooltip: 'Te Tai tokerau',
                },
                {
                    value: 'OTA',
                    label: 'Otago',
                    tooltip: 'Ō Tākou',
                },
                {
                    value: 'STL',
                    label: 'Southland',
                    tooltip: 'Murihiku',
                },
                {
                    value: 'TAS',
                    label: 'Tasman',
                    tooltip: 'Tasman',
                },
                {
                    value: 'TKI',
                    label: 'Taranaki',
                    tooltip: 'Taranaki',
                },
                {
                    value: 'WKO',
                    label: 'Waikato',
                    tooltip: 'Waikato',
                },
                {
                    value: 'WGN',
                    label: 'Wellington',
                    tooltip: 'Te Whanga-nui-a-Tara',
                },
                {
                    value: 'WTC',
                    label: 'West Coast',
                    tooltip: 'Te Taihau ā uru',
                },
                {
                    value: 'CIT',
                    label: 'Chatham Islands',
                    tooltip: 'Territory Wharekauri special island',
                },
            ];
        default:
            return [];
    }
};
exports.getStateOptions = getStateOptions;
const getTimezoneOpts = () => [
    { value: 'Australia/Sydney', label: 'Australia/Sydney', tooltip: 'Australia/Sydney' },
    { value: 'Australia/Brisbane', label: 'Australia/Brisbane', tooltip: 'Australia/Brisbane' },
    { value: 'Australia/Perth', label: 'Australia/Perth', tooltip: 'Australia/Perth' },
    { value: 'Australia/Adelaide', label: 'Australia/Adelaide', tooltip: 'Australia/Adelaide' },
    { value: 'Australia/Broken_Hill', label: 'Australia/Broken Hill', tooltip: 'Australia/Broken_Hill' },
    { value: 'Australia/Melbourne', label: 'Australia/Melbourne', tooltip: 'Australia/Melbourne' },
    { value: 'Australia/Darwin', label: 'Australia/Darwin', tooltip: 'Australia/Darwin' },
    { value: 'Australia/Hobart', label: 'Australia/Hobart', tooltip: 'Australia/Hobart' },
    { value: 'Australia/Lord_Howe', label: 'Australia/Lord Howe', tooltip: 'Australia/Lord_Howe' },
    { value: 'Australia/Eucla', label: 'Australia/Eucla', tooltip: 'Australia/Eucla' },
    { value: 'Australia/Lindeman', label: 'Australia/Lindeman', tooltip: 'Australia/Lindeman' },
    { value: 'Pacific/Chatham', label: 'Pacific/Chatham', tooltip: 'Pacific/Chatham' },
    { value: 'Pacific/Auckland', label: 'Pacific/Auckland', tooltip: 'Pacific/Auckland' },
];
exports.getTimezoneOpts = getTimezoneOpts;
const getTimezoneStateOpts = (countryCode) => {
    switch (countryCode) {
        case interfaces_1.CountryCode.NEWZEALAND:
            return [
                { value: 'Pacific/Chatham', label: 'Pacific/Chatham', tooltip: 'Pacific/Chatham' },
                { value: 'Pacific/Auckland', label: 'Pacific/Auckland', tooltip: 'Pacific/Auckland' },
            ];
        case interfaces_1.CountryCode.AUSTRALIA:
            return [
                { value: 'Australia/Sydney', label: 'Australia/Sydney', tooltip: 'Australia/Sydney' },
                { value: 'Australia/Brisbane', label: 'Australia/Brisbane', tooltip: 'Australia/Brisbane' },
                { value: 'Australia/Perth', label: 'Australia/Perth', tooltip: 'Australia/Perth' },
                { value: 'Australia/Adelaide', label: 'Australia/Adelaide', tooltip: 'Australia/Adelaide' },
                { value: 'Australia/Broken_Hill', label: 'Australia/Broken Hill', tooltip: 'Australia/Broken_Hill' },
                { value: 'Australia/Melbourne', label: 'Australia/Melbourne', tooltip: 'Australia/Melbourne' },
                { value: 'Australia/Darwin', label: 'Australia/Darwin', tooltip: 'Australia/Darwin' },
                { value: 'Australia/Hobart', label: 'Australia/Hobart', tooltip: 'Australia/Hobart' },
                { value: 'Australia/Lord_Howe', label: 'Australia/Lord Howe', tooltip: 'Australia/Lord_Howe' },
                { value: 'Australia/Eucla', label: 'Australia/Eucla', tooltip: 'Australia/Eucla' },
                { value: 'Australia/Lindeman', label: 'Australia/Lindeman', tooltip: 'Australia/Lindeman' },
            ];
        default:
            return [];
    }
};
exports.getTimezoneStateOpts = getTimezoneStateOpts;
const getTimezoneByPostcode = (branch, postcode) => {
    const postcodeNum = Number(postcode);
    switch (branch.country.country_code) {
        case interfaces_1.CountryCode.NEWZEALAND:
            return postcode === '8016' ? 'Pacific/Chatham' : 'Pacific/Auckland';
        case interfaces_1.CountryCode.AUSTRALIA:
            if (postcodeNum === 2880)
                return 'Australia/Broken_Hill';
            if (postcodeNum === 2898)
                return 'Australia/Lord_Howe';
            if (postcodeNum >= 2000 && postcodeNum <= 2999)
                return 'Australia/Sydney';
            if (postcodeNum >= 3000 && postcodeNum <= 3999)
                return 'Australia/Melbourne';
            if (postcodeNum >= 4000 && postcodeNum <= 4999)
                return 'Australia/Brisbane';
            if (postcodeNum >= 5000 && postcodeNum <= 5799)
                return 'Australia/Adelaide';
            if (postcodeNum === 6443)
                return 'Australia/Eucla';
            if (postcodeNum >= 6000 && postcodeNum <= 6797)
                return 'Australia/Perth';
            if (postcodeNum >= 7000 && postcodeNum <= 7799)
                return 'Australia/Hobart';
            if (postcodeNum >= 800 && postcodeNum <= 899)
                return 'Australia/Darwin';
            return branch.timezone;
        default:
            return branch.timezone;
    }
};
exports.getTimezoneByPostcode = getTimezoneByPostcode;
