"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deploymentStageEditAPI = exports.deploymentStageNewAPI = exports.updateDeploymentStageAPI = exports.DEPLOYMENT_STAGE_UPDATE_ACTION = exports.insertDeploymentStageAPI = exports.DEPLOYMENT_STAGE_INSERT_ACTION = exports.deploymentStageInfoAPI = exports.deploymentStageListAPI = exports.DEPLOYMENT_LIST_ACTION = void 0;
const _1 = require(".");
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Deployment-Stage#action-list
exports.DEPLOYMENT_LIST_ACTION = 'DEPLOYMENT_LIST';
const deploymentStageListAPI = () => (0, _1.api)('deployment_stage', { action: exports.DEPLOYMENT_LIST_ACTION });
exports.deploymentStageListAPI = deploymentStageListAPI;
const deploymentStageInfoAPI = (id) => (0, _1.api)('deployment_stage', {
    action: 'DEPLOYMENT_INFO',
    active_role: 1,
    fillable: {
        deployment_stage: {
            id,
        }
    }
});
exports.deploymentStageInfoAPI = deploymentStageInfoAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Deployment-Stage#action-insert_
exports.DEPLOYMENT_STAGE_INSERT_ACTION = 'DEPLOYMENT_INSERT';
const insertDeploymentStageAPI = (deployment_stage_name, deployment_stage_description, packages) => (0, _1.api)('deployment_stage', {
    action: exports.DEPLOYMENT_STAGE_INSERT_ACTION,
    fillable: {
        deployment_stage: {
            deployment_stage_name,
            deployment_stage_description,
        },
        packages,
    },
});
exports.insertDeploymentStageAPI = insertDeploymentStageAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Deployment-Stage#action-update_
exports.DEPLOYMENT_STAGE_UPDATE_ACTION = 'DEPLOYMENT_UPDATE';
const updateDeploymentStageAPI = (deployment_stage, packages) => (0, _1.api)('deployment_stage', {
    action: exports.DEPLOYMENT_STAGE_UPDATE_ACTION,
    fillable: {
        deployment_stage,
        packages,
    },
});
exports.updateDeploymentStageAPI = updateDeploymentStageAPI;
const deploymentStageNewAPI = () => (0, _1.api)('deployment_stage', {
    action: 'DEPLOYMENT_NEW',
    active_role: 1,
    fillable: {}
});
exports.deploymentStageNewAPI = deploymentStageNewAPI;
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Deployment-Stage#action-edit
const deploymentStageEditAPI = (id) => (0, _1.api)('deployment_stage', {
    action: 'DEPLOYMENT_EDIT',
    active_role: 1,
    fillable: {
        deployment_stage: {
            id,
        }
    }
});
exports.deploymentStageEditAPI = deploymentStageEditAPI;
