"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPasswordAPI = exports.forgotPasswordAPI = exports.authUserInfoAPI = exports.loginAPI = void 0;
const _Api_1 = require("@Api");
const loginAPI = (username, password) => (0, _Api_1.api)('login', { username, password }, undefined, undefined, false, false);
exports.loginAPI = loginAPI;
const authUserInfoAPI = () => (0, _Api_1.api)('auth_user_info', {}, undefined, undefined, false, true);
exports.authUserInfoAPI = authUserInfoAPI;
const forgotPasswordAPI = (email) => (0, _Api_1.api)('forgot_password', { email }, undefined, undefined, false, false);
exports.forgotPasswordAPI = forgotPasswordAPI;
const resetPasswordAPI = (token, password) => (0, _Api_1.api)('reset_password', { token, password }, undefined, undefined, false, false);
exports.resetPasswordAPI = resetPasswordAPI;
